import React from "react";
import { observer } from "mobx-react-lite";

// Components
import Dialog from "@material-ui/core/Dialog";
import { DraggablePaper } from "@discoverr/ui/components/Dialog/DraggablePaper";
import { SqlrlWindowDataTable } from "@discoverr/ui/components/Table/SqlrlWindowDataTable";
import { ProgramSchema, IProgramSchemaPayload } from "schema";

//
export interface IProgramReferalModalProps {
  open: boolean;
  onClose: () => void;
  onSelect: (item: { title: string | null; programId: string }) => void;
}
export const ProgramReferalModal: React.FunctionComponent<IProgramReferalModalProps> = observer(
  props => {
    return (
      <Dialog
        open={props.open}
        onClose={props.onClose}
        maxWidth="md"
        fullWidth={true}
        PaperComponent={DraggablePaper}
        PaperProps={{ style: { height: "100%" } }}
      >
        <SqlrlWindowDataTable
          schema={ProgramSchema}
          fields={[
            "programId",
            "title",
            "subtitle",
            "channel",
            "genre",
            "seasonNumber",
            "episodeNumber",
            "productionYear",
            "productionNumber"
          ]}
          title="Select referal program"
          widths={{
            title: 280,
            productionNumber: 180,
            genre: 200,
            subtitle: 180
          }}
          onRowClick={props.onSelect}
          showDragHandle
        />
      </Dialog>
    );
  }
);
