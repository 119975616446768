import React from "react";
import { observer } from "mobx-react-lite";

// Components
import Dialog from "@material-ui/core/Dialog";
import { DraggablePaper } from "@discoverr/ui/components/Dialog/DraggablePaper";
import { SqlrlWindowDataTable } from "@discoverr/ui/components/Table/SqlrlWindowDataTable";
import { BroadcastGallupSchema } from "schema";

//
export interface IGallupReferalModalProps {
  open: boolean;
  onClose: () => void;
  onSelect: (item: { title: string; gallupId: string }) => void;
}
export const GallupReferalModal: React.FunctionComponent<IGallupReferalModalProps> = observer(
  props => {
    return (
      <Dialog
        open={props.open}
        onClose={props.onClose}
        maxWidth="md"
        fullWidth={true}
        PaperComponent={DraggablePaper}
        PaperProps={{ style: { height: "100%" } }}
      >
        <SqlrlWindowDataTable
          schema={BroadcastGallupSchema}
          fields={[
            "startTime",
            "endTime",
            "duration",
            "channel",
            "title",
            "internalId",
            "form1",
            "gallupId"
          ]}
          title="Select Gallup program"
          widths={{
            title: 400,
            form1: 200
          }}
          onRowClick={props.onSelect}
          showDragHandle
        />
      </Dialog>
    );
  }
);
