import React from "react";
import { RouteComponentProps } from "@reach/router";

// Containers
import { DialogContainer } from "@discoverr/ui/containers/DialogContainer";

// Components
import Box from "@material-ui/core/Box";

import { TableContainer } from "@discoverr/ui/components/Table/TableContainer";
import {
  SqlrlWindowDataTable,
  ISqlrlWindowDataTableHandles
} from "@discoverr/ui/components/Table/SqlrlWindowDataTable";
import { useSqlrlDetailsModalLink } from "@discoverr/ui/components/Modal/SqlrlDetailsModal";

// Schema
import { SpaceSettingSchema } from "schema";
import { ISqlrlSchemaPayload } from "@discoverr/ui/utilities/sqlrl";

//
export interface ISpaceSettingsProps extends RouteComponentProps {}
export const SpaceSettings: React.FunctionComponent<ISpaceSettingsProps> = props => {
  const dialogContainer = DialogContainer.useContainer();
  const tableRef = React.useRef<
    ISqlrlWindowDataTableHandles<typeof SpaceSettingSchema>
  >();

  const [link, handles] = useSqlrlDetailsModalLink<typeof SpaceSettingSchema>();

  const handleRowClick = (
    row: ISqlrlSchemaPayload<typeof SpaceSettingSchema>
  ) => {
    dialogContainer.textField.create({
      title: "Rediger",
      message: "Skriv venligst din tekst forneden.",
      label: "Value",
      type: "textarea",
      initialValue: row.value,
      onSubmit: async (value: string | null) => {
        try {
          await SpaceSettingSchema.update(
            { value: value || "" },
            { filter: [{ fieldName: "code", value: row.code }] }
          );
          if (tableRef.current) {
            tableRef.current.refresh();
          }
        } catch (error) {
          dialogContainer.error.handleError(error);
        }
      }
    });
  };

  return (
    <React.Fragment>
      <TableContainer>
        <SqlrlWindowDataTable
          innerRef={tableRef}
          schema={SpaceSettingSchema}
          title="Indstillinger"
          fields={["code", "value"]}
          onRowClick={handleRowClick}
        />
      </TableContainer>
    </React.Fragment>
  );
};
