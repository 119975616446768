import React from "react";
import { Router } from "@reach/router";

import { AuthContainer } from "containers/AuthContainer";

// Utilities
import { backend } from "backend";

// Components
import { Bootstrap } from "@discoverr/ui/components/Bootstrap";

import { RouterFlexLayout } from "components/Layout";

// Views
import { Login } from "./views/Login";
import { App } from "./views/App";

// Constants
const theme = {
  primary: {
    light: "#415661",
    main: "#122c3a",
    dark: "#0c1e28"
  },
  secondary: {
    light: "#ffa733",
    main: "#ff9100",
    dark: "#b26500",
    contrastText: "#fff"
  }
};

export const Root = () => {
  return (
    <Bootstrap
      initialBackend={backend}
      AuthContainer={AuthContainer}
      theme={theme}
    >
      <Router component={RouterFlexLayout}>
        <Login path="/login" />
        <App path="/*" />
      </Router>
    </Bootstrap>
  );
};
