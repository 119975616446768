import React from "react";
import { RouteComponentProps } from "@reach/router";

// Utilities
import {
  ISqlrlSchemaPayload,
  getFilterFromObject,
} from "@discoverr/ui/utilities/sqlrl";

// Containers
import { DialogContainer } from "@discoverr/ui/containers/DialogContainer";

// Components
import Box from "@material-ui/core/Box";
import {
  ISqlrlWindowDataTableHandles,
  SqlrlWindowDataTable,
} from "@discoverr/ui/components/Table/SqlrlWindowDataTable";
import {
  SqlrlDetailsModal,
  useSqlrlDetailsModalLink,
} from "@discoverr/ui/components/Modal/SqlrlDetailsModal";
import { SqlrlDetailView } from "@discoverr/ui/components/Detail/SqlrlDetailView";
import { SqlrlAutoFormik } from "@discoverr/ui/components/Formik/SqlrlAutoFormik";
import { TableContainer } from "@discoverr/ui/components/Table/TableContainer";
import { FormikLookup } from "@discoverr/ui/components/Formik/FormikLookup";
import { FileUploadDialog } from "components/FileUploadDialog";

// Schemas
import {
  BroadcastSchema,
  ProgramBonanzaViewSchema,
  ProgramBonanzaSchema,
  ProgramChannelViewSchema,
  ProgramSchema,
} from "schema";
import {
  getFieldMeta,
  FormikSelect,
  IFormikSelectOption,
} from "@discoverr/ui/components/Formik";
import { backend } from "backend";
import { ProgramGenerelTab } from "components/ProgramGenerelTab";
import { BroadcastGenerelTab } from "components/BroadcastGenerelTab";
import { BroadcastListTab } from "components/BroadcastListTab";

// Constants
const statusOptions: IFormikSelectOption[] = [
  { value: "INDLÆST", label: "INDLÆST" },
  { value: "AFVENT", label: "AFVENT" },
  { value: "OVERFØRT", label: "OVERFØRT" },
];

//
export interface IBroadcastBonanzaProps extends RouteComponentProps {}
export const BroadcastBonanza: React.FunctionComponent<IBroadcastBonanzaProps> = (
  props
) => {
  const dialogContainer = DialogContainer.useContainer();

  const tableRef = React.useRef<
    ISqlrlWindowDataTableHandles<typeof ProgramBonanzaViewSchema>
  >();

  const [link, handles] = useSqlrlDetailsModalLink<
    typeof ProgramBonanzaViewSchema
  >();

  const [showUpload, setShowUploader] = React.useState(false);

  const handleRowClick = (
    row: ISqlrlSchemaPayload<typeof ProgramBonanzaViewSchema>
  ) => {
    handles.view([{ fieldName: "bonanzaId", value: row.bonanzaId }]);
  };

  const hancleCreate = () => {
    handles.create();
  };

  const handleUploadDocument = () => {
    setShowUploader(true);
  };

  const handleCloseUploadDocument = () => {
    setShowUploader(false);
    tableRef.current && tableRef.current.refresh();
  };

  const handleDeleteAllLines = () => {
    dialogContainer.confirm.create({
      title: "Slet alle linjer",
      message: "Bekræft sletning af linjer.",
      confirmText: "Bekræft",
      closeText: "Annuller",
      onConfirm: () => {
        if (tableRef.current) {
          ProgramBonanzaSchema.delete({
            filter: getFilterFromObject(tableRef.current.getFilterValues(), {
              wildcard: true,
            }) as any,
          })
            .then(() => {
              if (tableRef.current) {
                tableRef.current.refresh();
              }
            })
            .catch((error) => {
              dialogContainer.error.handleError(error);
            });
        }
      },
    });
  };

  const handleTransferApproved = () => {
    dialogContainer.loading.start({
      title: "Vent venligst",
      message: "Mens data sammenlignes...",
    });
    backend
      .get("/sqlrl/custom/sp_program_bonanza_match", {
        timeout: 60 * 10 * 1000,
      })
      .then(() => {
        if (tableRef.current) {
          tableRef.current.refresh();
        }
      })
      .catch(dialogContainer.error.handleError)
      .finally(() => dialogContainer.loading.stop());
  };

  return (
    <React.Fragment>
      <TableContainer>
        <SqlrlWindowDataTable
          schema={ProgramBonanzaViewSchema}
          innerRef={tableRef}
          title="Bonanza"
          fields={[
            "status",
            "productionNumber",
            "paymentYear",
            "title",
            "mediaTitle",
            "click",
            "role",
            "person",
            "programId",
            "bonanzaId",
          ]}
          widths={{ title: 400 }}
          contextMenuItems={[
            {
              label: "Opret ny",
              onClick: hancleCreate,
            },
            {
              label: "Upload fil",
              onClick: handleUploadDocument,
            },
            {
              label: "Slet alle linjer",
              onClick: handleDeleteAllLines,
            },
            {
              label: "Match",
              onClick: handleTransferApproved,
            },
          ]}
          onRowClick={handleRowClick}
        />
      </TableContainer>
      <SqlrlDetailsModal
        schema={ProgramBonanzaViewSchema}
        fields={["bonanzaId", "title", "programId"]}
        link={link}
        caption="Bonanza"
        title={"title"}
        editable={true}
        editRenderer={({ data, close, refresh }) => (
          <SqlrlAutoFormik
            initialValues={{}}
            schema={ProgramBonanzaViewSchema}
            fields={[
              "paymentYear",
              "title",
              "mediaTitle",
              "productionNumber",
              "click",
              "role",
              "person",
              "programId",
            ]}
            fieldRendererMap={{
              programId: ({ field, form, sqlrlField }) => (
                <FormikLookup
                  schema={ProgramSchema}
                  fields={[
                    "channel",
                    "title",
                    "subtitle",
                    "seasonNumber",
                    "episodeNumber",
                    "programId",
                  ]}
                  filter={[]}
                  valueField="programId"
                  labelField="title"
                  field={field}
                  meta={getFieldMeta(form, field.name)}
                  label={sqlrlField.label}
                />
              ),
            }}
            returnFields={[]}
            filter={
              data
                ? [
                    {
                      fieldName: "bonanzaId",
                      value: data.bonanzaId,
                    },
                  ]
                : []
            }
            onEdit={close}
            deletable
          />
        )}
        tabs={[
          {
            label: "General",
            renderer: ({ data }) => (
              <Box p={3}>
                <SqlrlDetailView
                  schema={ProgramBonanzaViewSchema}
                  fields={[
                    "status",
                    "productionNumber",
                    "paymentYear",
                    "title",
                    "mediaTitle",
                    "click",
                    "role",
                    "person",
                    "programId",
                    "bonanzaId",
                  ]}
                  filter={[
                    {
                      fieldName: "bonanzaId",
                      value: data.bonanzaId,
                    },
                  ]}
                  title="Start Forfra"
                />
              </Box>
            ),
          },
          {
            label: "Program",
            renderer: ({ data }) =>
              data.programId && (
                <ProgramGenerelTab programId={data.programId} />
              ),
          },
          {
            label: "Udsendelser",
            renderer: ({ data }) =>
              data.programId && <BroadcastListTab programId={data.programId} />,
          },
        ]}
      />
      <FileUploadDialog
        path="import.bonanza"
        open={showUpload}
        onClose={handleCloseUploadDocument}
      ></FileUploadDialog>
    </React.Fragment>
  );
};
