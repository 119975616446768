import React from "react";
import Box from "@material-ui/core/Box";

import { BroadcastSchema } from "schema";

import { SqlrlDetailView } from "@discoverr/ui/components/Detail/SqlrlDetailView";
import { ISqlrlSchemaPayload } from "@discoverr/ui/utilities/sqlrl";

//
export interface IBroadcastGenerelTabProps {
  broadcastId: ISqlrlSchemaPayload<typeof BroadcastSchema>["broadcastId"];
}
export const BroadcastGenerelTab = (props: IBroadcastGenerelTabProps) => {
  return (
    <Box p={3}>
      <SqlrlDetailView
        schema={BroadcastSchema}
        fields={[
          "startTime",
          "endTime",
          "programId",
          "channel",
          "title",
          "subtitle",
          "duration",
          "seasonNumber",
          "episodeNumber",
          "productionNumber",
          "productionYear",
          "productionCountry",
          "genre",
          "isReturn",
          "originalTitle",
          "seriesTitle",
          "seasonTitle",
          "announcedStartTime",
          "announcedEndTime",
          "status",
          "description",
        ]}
        filter={[
          {
            fieldName: "broadcastId",
            value: props.broadcastId,
          },
        ]}
        title="Udsendelse"
      />
    </Box>
  );
};
