import React, { useState, useRef, useEffect, useContext } from "react";
import { observer, Observer } from "mobx-react-lite";
import { RouteComponentProps } from "@reach/router";
import { FixedSizeList } from "react-window";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";

import ReactCrop, { Crop, PercentCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

// Stores
import { ViewStore } from "./ViewStore";

// Schema
import { PositionSchema } from "schema";

// Containers
import { DialogContainer } from "@discoverr/ui/containers/DialogContainer";

// Components
import {
  Paper,
  Grid,
  TextField,
  CircularProgress,
  Toolbar,
  Typography,
  Divider,
  Button,
  IconButton,
  Icon,
  Menu,
  MenuItem
} from "@material-ui/core";
import { AutocompleteSelect } from "@discoverr/ui/components/Select/AutocompleteSelect";

// Hooks
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles({
  container: {
    display: "flex",
    height: "100%",
    width: "100vw"
  },
  leftSidebar: {
    width: 320
  },
  main: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column"
  },
  mainHeader: {
    display: "flex",
    justifyContent: "center"
  },
  mainContent: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  rightSidebar: {
    width: 450,
    display: "flex",
    flexDirection: "column"
  },
  unReviewedFrame: {
    opacity: 0.2
  },
  imageButton: {
    position: "relative",
    border: "none",
    padding: 8,
    margin: 0,
    width: "100%",
    outline: "none",
    display: "flex"
  },
  creditRow: {
    display: "flex",
    flexDirection: "row",
    marginLeft: -8,
    marginRight: -8
  },
  creditRowTextField: {
    paddingLeft: 8,
    paddingRight: 8,
    width: 200
  },
  creditRowIcon: {
    marginTop: 4
  }
});

// Helpers
const getRolesOptions = (value: { inputValue: string | null }) => {
  return PositionSchema.select({
    fields: ["name"],
    filter: [{ fieldName: "name", value: value.inputValue + "*" }],
    limit: 50,
    offset: 0
  }).then(res => res.data.map((item: any) => ({ value: item.name })));
};

//
export interface IOcrProps extends RouteComponentProps {
  broadcastId?: string;
}
export const Ocr: React.SFC<IOcrProps> = observer((props: IOcrProps) => {
  const dialogContainer = DialogContainer.useContainer();
  const { current: viewStore } = useRef(new ViewStore());
  useEffect(() => {
    if (props.broadcastId) {
      viewStore.getSelectedFrames(props.broadcastId);
    }
  }, []);
  const classes = useStyles({});

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuCopy = () => {
    handleMenuClose();
    dialogContainer.textField.create({
      title: "Kopier",
      message:
        "Du er ved at fortage en kopiering af aflæsningerne til anden udsendelse.",
      label: "Destinations udsendelses id",
      onSubmit: async (value: string | null) => {
        if (props.broadcastId) {
          if (value) {
            try {
              dialogContainer.loading.start({
                title: "Arbejder...",
                message: "Filer kopieres"
              });
              await viewStore.copyProgram(props.broadcastId, value);

              dialogContainer.loading.stop();
            } catch (error) {
              dialogContainer.alert.create({
                title: "Kopiering fejlede",
                message: "Prøv igen eller kontakt support.",
                status: "error",
                onClose: () => {},
                onExited: () => {}
              });
            }
          } else {
            dialogContainer.alert.create({
              title: "Kopiering fejlede",
              message: "Udfyld venligst 'Destinations udsendelses id'.",
              status: "error",
              onClose: () => {},
              onExited: () => {}
            });
          }
        }
      }
    });
  };

  const [crop, setCrop] = useState<Crop>({ x: 0, y: 0 });
  const frames = viewStore.frames;
  const handleImageClick = (index: number) => () => {
    viewStore.selectFrame(index);
  };

  const handleClear = async (data: any) => {
    if (viewStore.selectedFrame) {
      viewStore.selectedFrame.clearCredits();
    }
  };

  const handleSave = async (data: any) => {
    await viewStore.createProrgam();
    props.navigate!("/approval");
  };

  const handleCrop = (crop: Crop) => {
    setCrop(crop);
  };

  const handleCropComplete = async (crop: Crop, percentCrop: PercentCrop) => {
    if (props.broadcastId && percentCrop.x && percentCrop.y) {
      setCrop({ x: 0, y: 0 });

      dialogContainer.loading.start({
        title: "Analyser udsnit",
        message: "Vent venligst et øjeblik..."
      });
      await viewStore.imageOcr(props.broadcastId, {
        x: percentCrop.x,
        y: percentCrop.y,
        width: percentCrop.width,
        height: percentCrop.height
      });
      dialogContainer.loading.stop();
    }
  };

  const rowRenderer = ({ index, key, style }: any) => {
    if (!frames) {
      return null;
    }
    const data = frames[index];
    if (!data) {
      return null;
    }
    return (
      <div
        key={key}
        style={style}
        className={!data.isReviewed ? classes.unReviewedFrame : undefined}
      >
        <Observer>
          {() => (
            <button
              className={classes.imageButton}
              onClick={handleImageClick(index)}
            >
              <img
                style={{ width: "100%", borderRadius: 8 }}
                src={data.thumbPath}
              />
            </button>
          )}
        </Observer>
      </div>
    );
  };
  return (
    <div className={classes.container}>
      <Paper className={classes.leftSidebar}>
        {frames && (
          <AutoSizer disableWidth>
            {({ height }: Size) => (
              <FixedSizeList
                height={height}
                itemCount={frames.length}
                itemSize={(9 / 16) * 320}
                width={320}
              >
                {rowRenderer}
              </FixedSizeList>
            )}
          </AutoSizer>
        )}
      </Paper>
      <div className={classes.main}>
        <div className={classes.mainHeader}>
          <Paper style={{ width: 960 }} square>
            <Toolbar style={{ height: 64 }}>
              <Typography variant="h6">
                {viewStore.frames
                  ? `${viewStore.reviewedCount} / ${viewStore.frames.length} er gennemgået`
                  : "Indlæser"}
              </Typography>{" "}
              <span style={{ flexGrow: 1 }} />
              <Button onClick={handleSave}>Opret program</Button>
              <IconButton onClick={handleMenuClick}>
                <Icon>more_vert</Icon>
              </IconButton>
              <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "top"
                }}
                transformOrigin={{
                  horizontal: "right",
                  vertical: "top"
                }}
              >
                <MenuItem
                  onClick={() => {
                    props.navigate!("../initialize");
                  }}
                >
                  Start forfra
                </MenuItem>
                <MenuItem onClick={handleMenuCopy}>Kopier</MenuItem>
              </Menu>
            </Toolbar>
          </Paper>
        </div>
        <div className={classes.mainContent}>
          <Paper
            style={{ width: 960, height: (9 / 16) * 960, overflow: "hidden" }}
          >
            {viewStore.selectedFrame && viewStore.selectedFrame.path ? (
              <ReactCrop
                style={{ display: "flex", maxWidth: "1280px" }}
                src={viewStore.selectedFrame.path}
                crop={crop}
                onChange={handleCrop}
                onComplete={handleCropComplete}
              />
            ) : (
              <div
                style={{
                  width: 960,
                  height: (9 / 16) * 960,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <CircularProgress />
              </div>
            )}
          </Paper>
        </div>
      </div>
      <Paper className={classes.rightSidebar} square>
        <Toolbar>
          <Typography variant="h6">Medvirkende</Typography>
          <span style={{ flexGrow: 1 }} />
          <Button onClick={handleClear}>Ryd</Button>
        </Toolbar>
        <Divider />
        {viewStore.selectedFrame && (
          <div style={{ padding: 16, overflowY: "auto", flexGrow: 1 }}>
            {viewStore.selectedFrame.credits
              ? viewStore.selectedFrame.credits.map(item => (
                  <div className={classes.creditRow}>
                    <div className={classes.creditRowTextField}>
                      <TextField
                        label="Navn"
                        value={item.name}
                        onChange={item.handleNameChange}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />
                    </div>
                    <div className={classes.creditRowTextField}>
                      <AutocompleteSelect
                        label="Position"
                        value={{ value: item.role }}
                        getOptions={getRolesOptions}
                        onChange={item.handleRoleSelect}
                        margin="dense"
                        fullWidth
                      />
                    </div>
                    <div className={classes.creditRowIcon}>
                      <IconButton onClick={item.handleDeleteClick}>
                        <Icon>delete</Icon>
                      </IconButton>
                    </div>
                  </div>
                ))
              : null}
            <Grid container justify="center" style={{ marginTop: 8 }}>
              <Grid item>
                <IconButton onClick={viewStore.selectedFrame.handleAddClick}>
                  <Icon fontSize="large">add_circle</Icon>
                </IconButton>
              </Grid>
            </Grid>
          </div>
        )}
      </Paper>
    </div>
  );
});
