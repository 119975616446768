import React from "react";

// Containers
import { AuthContainer } from "containers/AuthContainer";

// Utilities
import { ISqlrlSchemaPayload } from "@discoverr/ui/utilities/sqlrl";

// Components
import {
  SqlrlWindowDataTable,
  ISqlrlWindowDataTableHandles,
} from "@discoverr/ui/components/Table/SqlrlWindowDataTable";
import { DataTableDialog } from "@discoverr/ui/components/Dialog";

import {
  SqlrlAutoFormikModal,
  useSqlrlAutoFormikModalLink,
} from "@discoverr/ui/components/Modal/SqlrlAutoFormikModal";
import {
  FormikLookup,
  getFieldMeta,
  FormikTextField,
  FormikDatePicker,
  FormikTimePicker,
  FormikSelect,
  IFormikSelectOption,
} from "@discoverr/ui/components/Formik";
import { FormikDateTimePicker } from "@discoverr/ui/components/Formik/FormikDateTimePicker";
import { baseURL } from "backend";

// Models
import {
  PersonSchema,
  IBroadcastSchemaPayload,
  ProgramSchema,
  IProgramSchemaPayload,
  ProgramChannelViewSchema,
  PositionSchema,
} from "schema";
import { DialogContainer } from "@discoverr/ui/containers/DialogContainer";

// Constants
const statusOptions: IFormikSelectOption[] = [
  { value: "AUTO", label: "AUTO" },
  { value: "GODKENDT", label: "GODKENDT" },
  { value: "KLAR", label: "KLAR" },
  { value: "OCR", label: "OCR" },
  { value: "UDSKUDT", label: "UDSKUDT" },
];

//
export interface IProgramPersonTabProps {
  data: Pick<ISqlrlSchemaPayload<typeof ProgramSchema>, "programId">;
}
export const ProgramPersonTab: React.FunctionComponent<IProgramPersonTabProps> = ({
  data,
}) => {
  const authContainer = AuthContainer.useContainer();
  const dialogContainer = DialogContainer.useContainer();
  const tableRef = React.useRef<
    ISqlrlWindowDataTableHandles<typeof PersonSchema>
  >();

  const [link, handles] = useSqlrlAutoFormikModalLink<typeof PersonSchema>();

  const handleSuccess = () => {
    if (tableRef.current) {
      tableRef.current.refresh();
    }
  };
  const [moveLookupOpen, setMoveLookup] = React.useState(false);

  const handleMoveLookupClose = () => {
    setMoveLookup(false);
  };

  const handleMovePeopleOpen = () => {
    setMoveLookup(true);
  };
  const handleMovePersonSelect = (
    row: Pick<IProgramSchemaPayload, "programId">
  ) => {
    dialogContainer.confirm.create({
      title: "Bekræft flytning",
      message: "Personer vil blive flyttet til det valgte program.",
      confirmText: "Bekræft",
      closeText: "Annuller",
      onConfirm: () => {
        PersonSchema.update(
          { programId: row.programId },
          { filter: [{ fieldName: "programId", value: data.programId }] }
        )
          .then(() => {
            handleMoveLookupClose();
            tableRef.current && tableRef.current.refresh();
          })
          .catch(dialogContainer.error.handleError);
      },
    });
  };

  const handleDownloadPeople = async () => {
    const url =
      baseURL +
      "sqlrl/export/vw_export_boadcast_person?fields=broadcastId,startTime,endTime,channel,title,subtitle,duration,seasonNumber,episodeNumber,productionNumber,productionYear,productionCountry,genre,programId,name,role&filter=programId:" +
      data.programId +
      "&accessToken=" +
      authContainer.token;
    const win = window.open(url, "_blank");
  };

  return (
    <React.Fragment>
      <SqlrlWindowDataTable
        innerRef={tableRef}
        schema={PersonSchema}
        fields={["name", "role", "rowId"]}
        filter={[
          {
            fieldName: "programId",
            value: data.programId,
          },
        ]}
        title="Person"
        menuBarItems={[
          {
            label: "Opret Person",
            iconName: "add",
            onClick: handles.create,
          },
        ]}
        onRowClick={(row) => {
          handles.update([{ fieldName: "rowId", value: row.rowId }]);
        }}
        contextMenuItems={[
          {
            label: "Flyt alle personer",
            onClick: handleMovePeopleOpen,
          },
          {
            label: "Eksportere personer",
            onClick: handleDownloadPeople,
          },
        ]}
      />
      <SqlrlAutoFormikModal
        title="Message In"
        link={link}
        schema={PersonSchema}
        fields={["name", "role"]}
        returnFields={[]}
        fieldRendererMap={{
          role: ({ field, form, sqlrlField }) => (
            <FormikLookup
              schema={PositionSchema}
              fields={["name"]}
              filter={[]}
              valueField="name"
              labelField="name"
              field={field}
              meta={getFieldMeta(form, field.name)}
              label={sqlrlField.label}
            />
          ),
        }}
        initialValues={{}}
        onSuccess={() => {
          handleSuccess();
        }}
        onTransformValues={(values) => ({
          ...values,
          programId: data.programId,
        })}
        onDelete={handles.close}
        deletable
      />
      <DataTableDialog
        title="Vælg Program"
        open={moveLookupOpen}
        onClose={handleMoveLookupClose}
        onRowSelect={handleMovePersonSelect}
        schema={ProgramSchema}
        fields={[
          "productionNumber",
          "title",
          "seasonNumber",
          "episodeNumber",
          "productionYear",
          "productionCountry",
          "programId",
        ]}
        widths={{
          title: 280,
        }}
      />
    </React.Fragment>
  );
};
