import React from "react";
import Box from "@material-ui/core/Box";

import { ProgramSchema } from "schema";

import { SqlrlDetailView } from "@discoverr/ui/components/Detail/SqlrlDetailView";
import { ISqlrlSchemaPayload } from "@discoverr/ui/utilities/sqlrl";

//
export interface IProgramGenerelTabProps {
  programId: ISqlrlSchemaPayload<typeof ProgramSchema>["programId"];
}
export const ProgramGenerelTab = (props: IProgramGenerelTabProps) => {
  return (
    <Box p={3}>
      <SqlrlDetailView
        schema={ProgramSchema}
        fields={[
          "programId",
          "title",
          "subtitle",
          "channel",
          "duration",
          "genre",
          "seasonNumber",
          "episodeNumber",
          "productionYear",
          "originalTitle",
          "seriesTitle",
          "seasonTitle",
          "description",
          "productionNumber",
        ]}
        filter={[
          {
            fieldName: "programId",
            value: props.programId,
          },
        ]}
        title="Program"
      />
    </Box>
  );
};
