import { action, observable, IObservableArray, computed } from "mobx";

// Backend
import { backend } from "backend";
export type ICreateProgram = {
  fileName: string;
  name: string;
  role: string;
};

export const createProgram = ({
  broadcastId,
  data
}: {
  broadcastId: string;
  data: ICreateProgram[];
}) =>
  backend.post<[]>("broadcast.approval.createProgram", data, {
    params: { broadcastId }
  });

// Types
export interface IFrameModel {
  fileName: string;
  path: string;
}

export type IFramesParams = {
  broadcastId: string;
  startOffset: number;
  startDuration: number;
  endOffset: number;
  endDuration: number;
};

export interface IFrameDataModel {
  path: string;
  credits: ICredits[];
}

export type ICredits = {
  name: string;
  role: string;
};

// Api
export const getSelectedFrames = ({ broadcastId }: { broadcastId: string }) =>
  backend.get<IFrameModel[]>("broadcast.approval.getSelectedFrames", {
    params: { broadcastId }
  });

export const getFrameData = ({
  broadcastId,
  fileName
}: {
  broadcastId: string;
  fileName: string;
}) =>
  backend.get<IFrameDataModel[]>("broadcast.approval.getFrameData", {
    params: { broadcastId, fileName }
  });

export const imageOcr = ({
  broadcastId,
  url,
  fileName,
  x,
  y,
  widthPercentage,
  heightPercentage
}: {
  broadcastId: string;
  url: string;
  fileName: string;
  x: number;
  y: number;
  widthPercentage: number;
  heightPercentage: number;
}) =>
  backend.get<IFrameDataModel[]>("broadcast.approval.image.ocr", {
    params: {
      broadcastId,
      url,
      fileName,
      x,
      y,
      widthPercentage,
      heightPercentage
    }
  });

export const copyBroadcast = ({
  fromBroadcastId,
  toBroadcastId
}: {
  fromBroadcastId: string;
  toBroadcastId: string;
}) =>
  backend.get("broadcast.approval.copy", {
    params: { from: fromBroadcastId, to: toBroadcastId }
  });

// Types
type IFrames = IFrameModel;
type ISelectableFrames = { selected?: boolean } & IFrames;

//
export class Credit {
  creditId: number;
  @observable name = "";
  @observable role = "";

  constructor(
    private frame: FrameContent,
    id: number,
    name: string,
    role: string
  ) {
    this.creditId = id;
    this.name = name;
    this.role = role;
  }

  @action.bound handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.name = event.currentTarget.value;
  }
  @action.bound handleRoleSelect(option: { value: string } | null) {
    if (option) {
      this.role = option.value;
      this.frame.store.whitelistCredit(this);
    }
  }

  serilize() {
    return {
      name: this.name,
      role: this.role
    };
  }

  @action.bound handleDeleteClick() {
    this.frame.removeCredit(this);
  }
}

export class FrameContent {
  broadcastId: string;
  fileName: string;
  thumbPath: string;
  @observable path: string | null = null;
  @observable credits: Credit[] | null = null;

  @computed get isReviewed() {
    return this.credits !== null;
  }

  constructor(public store: ViewStore, broadcastId: string, frame: IFrames) {
    this.broadcastId = broadcastId;
    this.fileName = frame.fileName;
    this.thumbPath = frame.path;
  }

  @action async getData() {
    if (this.credits === null) {
      const response = await getFrameData({
        broadcastId: this.broadcastId,
        fileName: this.fileName
      });
      const frameData = response.data[0];
      if (frameData) {
        this.path = frameData.path;
        this.credits = frameData.credits
          .filter(item => !this.store.creditBlacklist.has(item.name))
          .map((item, index) => {
            const whitelistCredit = this.store.creditWhitelist.get(item.name);
            const credit = new Credit(
              this,
              index,
              item.name,
              whitelistCredit ? whitelistCredit.role : item.role
            );
            if (!whitelistCredit && item.role) {
              this.store.whitelistCredit(credit);
            }
            return credit;
          });
      }
    }
  }

  @action addCredit(name: string, role: string) {
    if (this.credits) {
      const prevId =
        this.credits.length > 0
          ? this.credits[this.credits.length - 1].creditId
          : 0;
      this.credits.push(new Credit(this, prevId + 1, name, role));
    }
  }

  @action removeCredit(credit: Credit) {
    if (this.credits) {
      (this.credits as IObservableArray<Credit>).remove(credit);
      this.store.blacklistlistCredit(credit);
    }
  }

  @action clearCredits() {
    (this.credits as IObservableArray<Credit>).clear();
  }

  @action.bound handleAddClick() {
    this.addCredit("", "");
  }
}

export class ViewStore {
  @observable selectedIndex: number = 0;
  @observable frames: FrameContent[] | null = null;

  @observable creditWhitelist: Map<string, Credit> = new Map();
  @observable creditBlacklist: Map<string, Credit> = new Map();

  @computed get selectedFrame() {
    if (this.frames === null) {
      return null;
    }
    return this.frames[this.selectedIndex];
  }

  @computed get reviewedCount() {
    if (this.frames === null) {
      return 0;
    }
    return this.frames.filter(item => item.isReviewed).length;
  }

  @computed get isReviewed() {
    if (this.frames === null) {
      return false;
    }
    return this.frames.length === this.reviewedCount;
  }

  constructor() {}

  @action async getSelectedFrames(broadcastId: string) {
    const response = await getSelectedFrames({ broadcastId });
    this.frames = response.data.map(
      item => new FrameContent(this, broadcastId, item)
    );
    if (this.frames.length > 0) {
      this.frames[this.selectedIndex].getData();
    }
  }

  @action selectFrame(index: number) {
    if (this.frames) {
      this.selectedIndex = index;
      this.frames[index].getData();
    }
  }

  @action whitelistCredit(credit: Credit) {
    this.creditWhitelist.set(credit.name, credit);
  }
  @action blacklistlistCredit(credit: Credit) {
    this.creditBlacklist.set(credit.name, credit);
  }

  @action async imageOcr(
    broadcastId: string,
    crop: { x: number; y: number; width?: number; height?: number }
  ) {
    if (this.selectedFrame && this.selectedFrame.path) {
      const response = await imageOcr({
        broadcastId,
        url: this.selectedFrame.path,
        fileName: this.selectedFrame.fileName,
        x: crop.x,
        y: crop.y,
        widthPercentage: crop.width || 0,
        heightPercentage: crop.height || 0
      });
      for (let i = 0; i < response.data[0].credits.length; i++) {
        const credit = response.data[0].credits[i];
        this.selectedFrame.addCredit(credit.name, credit.role);
      }
    }
  }

  @action async copyProgram(fromBroadcastId: string, toBroadcastId: string) {
    return copyBroadcast({ fromBroadcastId, toBroadcastId });
  }

  @action async createProrgam() {
    if (this.frames) {
      const creditMap: object = {};

      const data = this.frames
        .filter(item => !!item.credits)
        .map(item => {
          item.credits!.map(credit => {
            const key = `${credit.name}_${credit.role}`;
            if (!(creditMap as any)[key]) {
              (creditMap as any)[key] = {
                fileName: item.fileName,
                name: credit.name,
                role: credit.role
              };
            }
          });
        });

      if (this.frames.length > 0) {
        await createProgram({
          broadcastId: this.frames[0].broadcastId,
          data: Object.keys(creditMap).map(key => (creditMap as any)[key])
        });
      }
    }
  }
}
