import React from "react";
import { Router, RouteComponentProps } from "@reach/router";

// Views
import { List } from "./views/List";
import { Tool } from "./views/Tool";

// Components
import { RouterFlexLayout } from "components/Layout";

//
export interface IApprovalProps extends RouteComponentProps {}
export const Approval: React.FunctionComponent<IApprovalProps> = props => {
  return (
    <Router component={RouterFlexLayout}>
      <Tool path=":broadcastId/*" />
      <List default />
    </Router>
  );
};
