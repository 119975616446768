import React from "react";
import { RouteComponentProps } from "@reach/router";

// Utilities
import {
  ISqlrlSchemaPayload,
  getFilterFromObject,
} from "@discoverr/ui/utilities/sqlrl";

// Containers
import { DialogContainer } from "@discoverr/ui/containers/DialogContainer";

// Components
import Box from "@material-ui/core/Box";
import {
  ISqlrlWindowDataTableHandles,
  SqlrlWindowDataTable,
} from "@discoverr/ui/components/Table/SqlrlWindowDataTable";
import {
  SqlrlDetailsModal,
  useSqlrlDetailsModalLink,
} from "@discoverr/ui/components/Modal/SqlrlDetailsModal";
import { SqlrlDetailView } from "@discoverr/ui/components/Detail/SqlrlDetailView";
import { SqlrlAutoFormik } from "@discoverr/ui/components/Formik/SqlrlAutoFormik";
import { TableContainer } from "@discoverr/ui/components/Table/TableContainer";
import { FormikLookup } from "@discoverr/ui/components/Formik/FormikLookup";
import { FileUploadDialog } from "components/FileUploadDialog";

// Schemas
import {
  BroadcastSchema,
  BroadcastCopydanSchema,
  BroadcastCopydanViewSchema,
} from "schema";
import { getFieldMeta } from "@discoverr/ui/components/Formik";
import { backend } from "backend";
import { ProgramGenerelTab } from "components/ProgramGenerelTab";
import { BroadcastGenerelTab } from "components/BroadcastGenerelTab";
import { BroadcastListTab } from "components/BroadcastListTab";

//
export interface IBroadcastCopydanProps extends RouteComponentProps {}
export const BroadcastCopydan: React.FunctionComponent<IBroadcastCopydanProps> =
  (props) => {
    const dialogContainer = DialogContainer.useContainer();

    const tableRef =
      React.useRef<
        ISqlrlWindowDataTableHandles<typeof BroadcastCopydanViewSchema>
      >();

    const [link, handles] =
      useSqlrlDetailsModalLink<typeof BroadcastCopydanViewSchema>();

    const [showUpload, setShowUploader] = React.useState(false);

    const handleRowClick = (
      row: ISqlrlSchemaPayload<typeof BroadcastCopydanViewSchema>
    ) => {
      handles.view([{ fieldName: "copydanId", value: row.copydanId }]);
    };

    const hancleCreate = () => {
      handles.create();
    };

    const handleUploadDocument = () => {
      setShowUploader(true);
    };

    const handleCloseUploadDocument = () => {
      setShowUploader(false);
      tableRef.current && tableRef.current.refresh();
    };

    const handleDeleteAllLines = () => {
      dialogContainer.confirm.create({
        title: "Slet alle linjer",
        message: "Bekræft sletning af linjer.",
        confirmText: "Bekræft",
        closeText: "Annuller",
        onConfirm: () => {
          if (tableRef.current) {
            BroadcastCopydanSchema.delete({
              filter: getFilterFromObject(tableRef.current.getFilterValues(), {
                wildcard: true,
              }) as any,
            })
              .then(() => {
                if (tableRef.current) {
                  tableRef.current.refresh();
                }
              })
              .catch((error) => {
                dialogContainer.error.handleError(error);
              });
          }
        },
      });
    };

    const handleTransferApproved = () => {
      dialogContainer.loading.start({
        title: "Vent venligst",
        message: "Mens data sammenlignes...",
      });
      backend
        .get("/sqlrl/custom/sp_broadcast_copydan_match", {
          timeout: 60 * 10 * 1000,
        })
        .then(() => {
          if (tableRef.current) {
            tableRef.current.refresh();
          }
        })
        .catch(dialogContainer.error.handleError)
        .finally(() => dialogContainer.loading.stop());
    };

    return (
      <React.Fragment>
        <TableContainer>
          <SqlrlWindowDataTable
            schema={BroadcastCopydanViewSchema}
            innerRef={tableRef}
            title="Copydan"
            fields={[
              "announcedStartTime",
              "id",
              "channel",
              "reportDate",
              "title",
              "seasonId",
              "displayYear",
              "flow",
              "copydanId",
            ]}
            widths={{ title: 400 }}
            contextMenuItems={[
              {
                label: "Opret ny",
                onClick: hancleCreate,
              },
              {
                label: "Upload fil",
                onClick: handleUploadDocument,
              },
              {
                label: "Slet alle linjer",
                onClick: handleDeleteAllLines,
              },
              {
                label: "Match",
                onClick: handleTransferApproved,
              },
            ]}
            onRowClick={handleRowClick}
          />
        </TableContainer>
        <SqlrlDetailsModal
          schema={BroadcastCopydanViewSchema}
          fields={["copydanId", "title", "programId", "broadcastId"]}
          link={link}
          caption="Copydan"
          title="title"
          editable={true}
          editRenderer={({ data, close, refresh }) => (
            <SqlrlAutoFormik
              initialValues={{}}
              schema={BroadcastCopydanSchema}
              fields={["titleSeasonEpisode", "broadcastId"]}
              fieldRendererMap={{
                broadcastId: ({ field, form, sqlrlField }) => (
                  <FormikLookup
                    schema={BroadcastSchema}
                    fields={[
                      "channel",
                      "title",
                      "subtitle",
                      "seasonNumber",
                      "episodeNumber",
                      "broadcastId",
                    ]}
                    filter={[]}
                    valueField="broadcastId"
                    labelField="title"
                    field={field}
                    meta={getFieldMeta(form, field.name)}
                    label={sqlrlField.label}
                  />
                ),
              }}
              returnFields={[]}
              filter={
                data
                  ? [
                      {
                        fieldName: "copydanId",
                        value: data.copydanId,
                      },
                    ]
                  : []
              }
              onEdit={close}
              deletable
            />
          )}
          tabs={[
            {
              label: "General",
              renderer: ({ data }) => (
                <Box p={3}>
                  <SqlrlDetailView
                    schema={BroadcastCopydanViewSchema}
                    fields={[
                      "announcedStartTime",
                      "id",
                      "channel",
                      "reportDate",
                      "title",
                      "seasonId",
                      "displayYear",
                      "copydanId",
                    ]}
                    filter={[
                      {
                        fieldName: "copydanId",
                        value: data.copydanId,
                      },
                    ]}
                    title="Start Forfra"
                  />
                </Box>
              ),
            },
            {
              label: "Program",
              renderer: ({ data }) =>
                data.programId && (
                  <ProgramGenerelTab programId={data.programId} />
                ),
            },
            {
              label: "Udsendelse",
              renderer: ({ data }) =>
                data.broadcastId && (
                  <BroadcastGenerelTab broadcastId={data.broadcastId} />
                ),
            },
            {
              label: "Udsendelser",
              renderer: ({ data }) =>
                data.programId && (
                  <BroadcastListTab programId={data.programId} />
                ),
            },
          ]}
        />
        <FileUploadDialog
          path="import.copydan"
          open={showUpload}
          onClose={handleCloseUploadDocument}
        ></FileUploadDialog>
      </React.Fragment>
    );
  };
