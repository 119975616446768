import React from "react";
import { observer } from "mobx-react-lite";

// Components
import Dialog from "@material-ui/core/Dialog";
import { DraggablePaper } from "@discoverr/ui/components/Dialog/DraggablePaper";
import { SqlrlWindowDataTable } from "@discoverr/ui/components/Table/SqlrlWindowDataTable";
import { PositionSchema } from "schema";

//
export interface IJobFunctionModalProps {
  open: boolean;
  onClose: () => void;
  onSelect: (item: { name: string | null }) => void;
}
export const JobFunctionModal: React.FunctionComponent<IJobFunctionModalProps> = observer(
  (props) => {
    return (
      <Dialog
        open={props.open}
        onClose={props.onClose}
        maxWidth="md"
        fullWidth={true}
        PaperComponent={DraggablePaper}
        PaperProps={{ style: { height: "100%" } }}
      >
        <SqlrlWindowDataTable
          schema={PositionSchema}
          fields={["name"]}
          title="Vælg job funktion"
          onRowClick={props.onSelect}
          showDragHandle
        />
      </Dialog>
    );
  }
);
