import React from "react";

// Utilities
import { ISqlrlSchemaPayload } from "@discoverr/ui/utilities/sqlrl";

// Components
import { SqlrlWindowDataTable } from "@discoverr/ui/components/Table/SqlrlWindowDataTable";

// Models
import { ProgramSchema, BroadcastCopydanViewSchema } from "schema";

//
export interface IBroadcastCopydanListTabProps {
  programId: ISqlrlSchemaPayload<typeof ProgramSchema>["programId"];
}
export const BroadcastCopydanListTab: React.FunctionComponent<IBroadcastCopydanListTabProps> = ({
  programId,
}) => {
  return (
    <React.Fragment>
      <SqlrlWindowDataTable
        schema={BroadcastCopydanViewSchema}
        fields={[
          "announcedStartTime",
          "id",
          "channel",
          "reportDate",
          "title",
          "seasonId",
          "displayYear",
          "copydanId",
        ]}
        widths={{ title: 300 }}
        filter={[
          {
            fieldName: "programId",
            value: programId,
          },
        ]}
        title="Copydan"
      />
    </React.Fragment>
  );
};
