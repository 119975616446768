import React from "react";
import { RouteComponentProps } from "@reach/router";

// Backend
import { backend } from "backend";

// Containers
import { DialogContainer } from "@discoverr/ui/containers/DialogContainer";

// Utilities
import {
  getFilterFromObject,
  getQueryFromFilter,
} from "@discoverr/ui/utilities/sqlrl";

// Schema
import { BroadcastGallupSchema } from "schema";

// Components
import { TableContainer } from "@discoverr/ui/components/Table/TableContainer";
import {
  SqlrlWindowDataTable,
  ISqlrlWindowDataTableHandles,
} from "@discoverr/ui/components/Table/SqlrlWindowDataTable";
import { FileUploadDialog } from "components/FileUploadDialog";

//
export interface IGallupProps extends RouteComponentProps {}
export const Gallup: React.FunctionComponent<IGallupProps> = (props) => {
  const dialogContainer = DialogContainer.useContainer();
  const tableRef =
    React.useRef<ISqlrlWindowDataTableHandles<typeof BroadcastGallupSchema>>();

  const [showUpload, setShowUploader] = React.useState(false);

  const handleCopyToBroadcast = () => {
    dialogContainer.textField.create({
      title: "Bekræft kopiering af Gallup udsendelser",
      message:
        "Produktions nummeret vil blive auto genereret hvis ikke feltet bliver udfyldt.",
      label: "Production number",
      onSubmit: (value) => {
        if (tableRef.current) {
          backend.get("broadcast.gallup.copy", {
            params: {
              productionNumber: value,
              filter: getQueryFromFilter(
                getFilterFromObject(tableRef.current.getFilterValues(), {
                  wildcard: true,
                })
              ),
            },
          });
        }
      },
    });
  };

  const handleUploadDocument = () => {
    setShowUploader(true);
  };

  const handleCloseUploadDocument = () => {
    setShowUploader(false);
    tableRef.current && tableRef.current.refresh();
  };

  return (
    <TableContainer>
      <SqlrlWindowDataTable
        innerRef={tableRef}
        schema={BroadcastGallupSchema}
        title="Gallup"
        fields={[
          "startTime",
          "endTime",
          "duration",
          "channel",
          "title",
          "internalId",
          "form1",
          "episode",
          "productionYear",
        ]}
        widths={{
          title: 400,
          form1: 200,
          productionYear: 180,
        }}
        contextMenuItems={[
          { label: "Kopier til udsendelser", onClick: handleCopyToBroadcast },
          {
            label: "Upload fil",
            onClick: handleUploadDocument,
          },
        ]}
      />

      <FileUploadDialog
        path="import.nielsen"
        open={showUpload}
        onClose={handleCloseUploadDocument}
      />
    </TableContainer>
  );
};
