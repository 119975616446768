import React from "react";
import { RouteComponentProps } from "@reach/router";

// Utilities
import {
  ISqlrlSchemaPayload,
  getQueryFromFilter,
  getFilterFromObject,
} from "@discoverr/ui/utilities/sqlrl";

// Containers
import { DialogContainer } from "@discoverr/ui/containers/DialogContainer";

// Components
import Box from "@material-ui/core/Box";
import {
  ISqlrlWindowDataTableHandles,
  SqlrlWindowDataTable,
} from "@discoverr/ui/components/Table/SqlrlWindowDataTable";
import {
  SqlrlDetailsModal,
  useSqlrlDetailsModalLink,
} from "@discoverr/ui/components/Modal/SqlrlDetailsModal";
import { SqlrlDetailView } from "@discoverr/ui/components/Detail/SqlrlDetailView";
import { SqlrlAutoFormik } from "@discoverr/ui/components/Formik/SqlrlAutoFormik";
import { TableContainer } from "@discoverr/ui/components/Table/TableContainer";
import { FormikLookup } from "@discoverr/ui/components/Formik/FormikLookup";
import { FileUploadDialog } from "components/FileUploadDialog";

// Schemas
import {
  BroadcastSchema,
  BroadcastReplayViewSchema,
  BroadcastManualSchema,
  BroadcastReplaySchema,
  ProgramChannelViewSchema,
} from "schema";
import {
  getFieldMeta,
  FormikSelect,
  IFormikSelectOption,
} from "@discoverr/ui/components/Formik";
import { backend } from "backend";
import { ProgramGenerelTab } from "components/ProgramGenerelTab";
import { BroadcastGenerelTab } from "components/BroadcastGenerelTab";
import { BroadcastListTab } from "components/BroadcastListTab";

// Constants
const statusOptions: IFormikSelectOption[] = [
  { value: "INDLÆST", label: "INDLÆST" },
  { value: "AFVENT", label: "AFVENT" },
  { value: "OVERFØRT", label: "OVERFØRT" },
];

//
export interface IBroadcastReplayProps extends RouteComponentProps {}
export const BroadcastReplay: React.FunctionComponent<IBroadcastReplayProps> = (
  props
) => {
  const dialogContainer = DialogContainer.useContainer();

  const tableRef = React.useRef<
    ISqlrlWindowDataTableHandles<typeof BroadcastReplayViewSchema>
  >();

  const [link, handles] = useSqlrlDetailsModalLink<
    typeof BroadcastReplayViewSchema
  >();

  const [showUpload, setShowUploader] = React.useState(false);

  const handleRowClick = (
    row: ISqlrlSchemaPayload<typeof BroadcastReplayViewSchema>
  ) => {
    handles.view([{ fieldName: "replayId", value: row.replayId }]);
  };

  const hancleCreate = () => {
    handles.create();
  };

  const handleUploadDocument = () => {
    setShowUploader(true);
  };

  const handleCloseUploadDocument = () => {
    setShowUploader(false);
    tableRef.current && tableRef.current.refresh();
  };

  const handleDeleteAllLines = () => {
    dialogContainer.confirm.create({
      title: "Slet alle linjer",
      message: "Bekræft sletning af linjer.",
      confirmText: "Bekræft",
      closeText: "Annuller",
      onConfirm: () => {
        if (tableRef.current) {
          BroadcastReplaySchema.delete({
            filter: getFilterFromObject(tableRef.current.getFilterValues(), {
              wildcard: true,
            }) as any,
          })
            .then(() => {
              if (tableRef.current) {
                tableRef.current.refresh();
              }
            })
            .catch((error) => {
              dialogContainer.error.handleError(error);
            });
        }
      },
    });
  };

  const handleMatch = () => {
    dialogContainer.loading.start({
      title: "Vent venligst",
      message: "Mens data sammenlignes...",
    });
    backend
      .get("/sqlrl/custom/sp_broadcast_replay_match", {
        timeout: 60 * 10 * 1000,
      })
      .then(() => {
        if (tableRef.current) {
          tableRef.current.refresh();
        }
      })
      .catch(dialogContainer.error.handleError)
      .finally(() => dialogContainer.loading.stop());
  };

  return (
    <React.Fragment>
      <TableContainer>
        <SqlrlWindowDataTable
          schema={BroadcastReplayViewSchema}
          innerRef={tableRef}
          title="Start Forfra"
          fields={[
            "status",
            "channel",
            "announcedStartTime",
            "title",
            "platform",
            "ssf",
            "ssfplus",
            "broadcastId",
            "programId",
            "replayId",
          ]}
          widths={{ title: 400 }}
          contextMenuItems={[
            {
              label: "Opret ny",
              onClick: hancleCreate,
            },
            {
              label: "Upload fil",
              onClick: handleUploadDocument,
            },
            {
              label: "Slet alle linjer",
              onClick: handleDeleteAllLines,
            },
            {
              label: "Match",
              onClick: handleMatch,
            },
          ]}
          onRowClick={handleRowClick}
        />
      </TableContainer>
      <SqlrlDetailsModal
        schema={BroadcastReplayViewSchema}
        fields={["replayId", "title", "programId", "broadcastId"]}
        link={link}
        caption="Start Forfra"
        title={"title"}
        editable={true}
        editRenderer={({ data, close, refresh }) => (
          <SqlrlAutoFormik
            initialValues={{}}
            schema={BroadcastReplaySchema}
            fields={[
              "channel",
              "announcedStartTime",
              "title",
              "platform",
              "ssf",
              "ssfplus",
              "broadcastId",
            ]}
            fieldRendererMap={{
              channel: ({ field, form, sqlrlField }) => (
                <FormikLookup
                  schema={ProgramChannelViewSchema}
                  fields={["channel"]}
                  filter={[]}
                  valueField="channel"
                  labelField="channel"
                  field={field}
                  meta={getFieldMeta(form, field.name)}
                  label={sqlrlField.label}
                />
              ),
              broadcastId: ({ field, form, sqlrlField }) => (
                <FormikLookup
                  schema={BroadcastSchema}
                  fields={[
                    "announcedStartTime",
                    "title",
                    "channel",
                    "seasonNumber",
                    "episodeNumber",
                    "productionNumber",
                    "broadcastId",
                  ]}
                  filter={[]}
                  valueField="broadcastId"
                  labelField="title"
                  field={field}
                  meta={getFieldMeta(form, field.name)}
                  label={sqlrlField.label}
                />
              ),
            }}
            returnFields={[]}
            filter={
              data
                ? [
                    {
                      fieldName: "replayId",
                      value: data.replayId,
                    },
                  ]
                : []
            }
            onEdit={close}
            deletable
          />
        )}
        tabs={[
          {
            label: "General",
            renderer: ({ data }) => (
              <Box p={3}>
                <SqlrlDetailView
                  schema={BroadcastReplayViewSchema}
                  fields={[
                    "channel",
                    "announcedStartTime",
                    "title",
                    "platform",
                    "ssf",
                    "ssfplus",
                    "broadcastId",
                    "programId",
                  ]}
                  filter={[
                    {
                      fieldName: "replayId",
                      value: data.replayId,
                    },
                  ]}
                  title="Start Forfra"
                />
              </Box>
            ),
          },
          {
            label: "Program",
            renderer: ({ data }) =>
              data.programId && (
                <ProgramGenerelTab programId={data.programId} />
              ),
          },
          {
            label: "Udsendelse",
            renderer: ({ data }) =>
              data.broadcastId && (
                <BroadcastGenerelTab broadcastId={data.broadcastId} />
              ),
          },
          {
            label: "Udsendelser",
            renderer: ({ data }) =>
              data.programId && <BroadcastListTab programId={data.programId} />,
          },
        ]}
      />
      <FileUploadDialog
        path="import.replay"
        open={showUpload}
        onClose={handleCloseUploadDocument}
      ></FileUploadDialog>
    </React.Fragment>
  );
};
