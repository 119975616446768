import { action, observable, IObservableArray } from "mobx";
import { Omit } from "utility-types";

// Backend
import { backend } from "backend";

// Schema
import { BroadcastSchema, IBroadcastSchemaPayload } from "schema";

// Types
export interface IFrameModel {
  fileName: string;
  path: string;
}

export type IFramesParams = {
  broadcastId: string;
  startOffset: number;
  startDuration: number;
  endOffset: number;
  endDuration: number;
};

// Api
export const init = ({ broadcastId }: { broadcastId: string }) =>
  backend.get<IBroadcastSchemaPayload[]>("broadcast.approval.init", {
    params: { broadcastId }
  });

export const createFrames = ({
  broadcastId,
  startOffset,
  startDuration,
  endOffset,
  endDuration
}: {
  broadcastId: string;
  startOffset: number;
  startDuration: number;
  endOffset: number;
  endDuration: number;
}) =>
  backend.get<IFrameModel[]>("broadcast.approval.createFrames", {
    params: { broadcastId, startOffset, startDuration, endOffset, endDuration },
    timeout: 300000
  });

export const getFrames = ({ broadcastId }: { broadcastId: string }) =>
  backend.get<IFrameModel[]>("broadcast.approval.getFrames", {
    params: { broadcastId }
  });

export const setSelectedFrames = ({
  broadcastId,
  data
}: {
  broadcastId: string;
  data: IFrameModel[];
}) =>
  backend.post<IFrameModel[]>("broadcast.approval.setSelectedFrames", data, {
    params: { broadcastId },
    timeout: 300000
  });

// Types
type IBroadcast = IBroadcastSchemaPayload;
type IFrames = IFrameModel;
type ISelectableFrames = { selected?: boolean } & IFrames;

export class ViewStore {
  isMultiSelecting: boolean = false;
  previousSelectedFrame: ISelectableFrames | null = null;

  @observable broadcast: IBroadcast | null = null;
  @observable frames: ISelectableFrames[] | null = null;

  @action async initRecord(broadcastId: string) {
    this.getFrames(broadcastId);
    const response = await init({ broadcastId });

    this.broadcast = response.data[0];
  }

  @action async createFrames(params: IFramesParams) {
    this.frames = null;
    const response = await createFrames(params);
    this.frames = response.data.map(item => ({ ...item, selected: false }));
  }

  @action async getFrames(broadcastId: string) {
    const response = await getFrames({ broadcastId });
    if (response.data.length) {
      this.frames = response.data.map(item => ({ ...item, selected: false }));
    }
  }

  @action async setSelectedFrames(broadcastId: string) {
    if (this.frames) {
      const data = this.frames
        .filter(item => item.selected)
        .map(item => ({ fileName: item.fileName, path: item.path }));
      const response = await setSelectedFrames({ broadcastId, data });
    }
  }

  @action.bound handleFrameToggle = (frame: ISelectableFrames) => () => {
    const item = (this.frames as IObservableArray<ISelectableFrames>).find(
      item => item === frame
    );
    if (item) {
      item.selected = !item.selected;

      if (this.isMultiSelecting) {
        this.multiSelect(item);
      }

      if (item.selected) {
        this.previousSelectedFrame = item;
      } else {
        this.previousSelectedFrame = null;
      }
    }
  };

  @action enableMultiSelect = () => {
    this.isMultiSelecting = true;
  };

  @action disableMultiSelect = () => {
    this.isMultiSelecting = false;
  };

  @action multiSelect(frame: ISelectableFrames) {
    if (this.frames) {
      if (this.previousSelectedFrame !== null) {
        const previousSelectedFrameIndex = (this.frames as IObservableArray<
          ISelectableFrames
        >).findIndex(item => item === this.previousSelectedFrame);
        const selectedFrameIndex = (this.frames as IObservableArray<
          ISelectableFrames
        >).findIndex(item => item === frame);
        const indices = [previousSelectedFrameIndex, selectedFrameIndex].sort(
          (a, b) => a - b
        );
        const start = indices[0];
        const end = indices[1];
        const count = end - start;
        for (let i = 0; i < count; i++) {
          const index = start + i;
          this.frames[index].selected = true;
        }
      }
    }
  }

  @action async postpone(value: string) {
    if (this.broadcast) {
      await BroadcastSchema.update(
        {
          broadcastId: this.broadcast.broadcastId,
          comment: value
        },
        {
          filter: [
            { fieldName: "broadcastId", value: this.broadcast.broadcastId }
          ]
        }
      );
    }
  }
}
