import React from "react";

// Utilities
import { ISqlrlSchemaPayload } from "@discoverr/ui/utilities/sqlrl";

// Components
import { SqlrlWindowDataTable } from "@discoverr/ui/components/Table/SqlrlWindowDataTable";

// Models
import { ProgramSchema, BroadcastReplayViewSchema } from "schema";

//
export interface IBroadcastReplayListTabProps {
  programId: ISqlrlSchemaPayload<typeof ProgramSchema>["programId"];
}
export const BroadcastReplayListTab: React.FunctionComponent<IBroadcastReplayListTabProps> = ({
  programId,
}) => {
  return (
    <React.Fragment>
      <SqlrlWindowDataTable
        schema={BroadcastReplayViewSchema}
        fields={[
          "status",
          "channel",
          "announcedStartTime",
          "title",
          "platform",
          "ssf",
          "ssfplus",
        ]}
        widths={{ title: 300 }}
        filter={[
          {
            fieldName: "programId",
            value: programId,
          },
        ]}
        title="Start Forfra"
      />
    </React.Fragment>
  );
};
