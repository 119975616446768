import React from "react";
import { Router, Link, Redirect, RouteComponentProps } from "@reach/router";

// Backend
import { backend } from "backend";

// Containers
import { AuthContainer } from "containers/AuthContainer";
import { DialogContainer } from "@discoverr/ui/containers/DialogContainer";

// Components
import { AuthGuard } from "@discoverr/ui/components/View";
import { LargeTopBar } from "@discoverr/ui/components/AppBar";
import { HeaderFlexLayout, RouterFlexLayout } from "components/Layout";

import { Approval } from "./views/Approval";
import { Broadcasts } from "./views/Broadcasts";
import { BroadcastReplay } from "./views/BroadcastReplay";
import { BroadcastAvu } from "./views/BroadcastAvu";
import { BroadcastBonanza } from "./views/BroadcastBonanza";
import { BroadcastCopydan } from "./views/BroadcastCopydan";
import { Gallup } from "./views/Gallup";
import { Ignore } from "./views/Ignore";
import { People } from "./views/People";
import { Member } from "./views/Member";
import { Positions } from "./views/Positions";
import { Programs } from "./views/Programs";
import { Users } from "./views/Users";
import { Status } from "./views/Status";
import { SpaceSettings } from "./views/SpaceSettings";
import { MemberProductionApproval } from "./views/MemberProductionApproval";
import { MemberProgramYear } from "./views/MemberProgramYear";
import { MemberProgramManual } from "./views/MemberProgramManual";
import { MemberProgramComments } from "./views/MemberProgramComments";
import { BroadcastImport } from "./views/BroadcastImport";

// Assets
const logoPNG = require("assets/logo_light.png");

//
export interface IAppProps extends RouteComponentProps {}
export const App: React.FunctionComponent<IAppProps> = (props) => {
  const authContainer = AuthContainer.useContainer();
  const dialogContainer = DialogContainer.useContainer();
  const routeValue = (props as { "*": string })["*"];

  const handleSyncronize = async () => {
    dialogContainer.loading.start({
      title: "Synkronisere",
      message: "Indlæser data fra TV stationer",
    });
    try {
      await backend.get("auto.sync.all", { timeout: 300000 });
      await backend.get("auto.sync.all", { timeout: 300000 });
      dialogContainer.loading.stop();
    } catch (error) {
      dialogContainer.loading.stop();
      dialogContainer.error.handleError(error);
    }
  };

  const handleAutorun = async () => {
    dialogContainer.loading.start({
      title: "Synkronisere",
      message: "kører autorun...",
    });
    try {
      await backend.get("/sqlrl/custom/sp_autorun", { timeout: 300000 });
      dialogContainer.loading.stop();
    } catch (error) {
      dialogContainer.loading.stop();
      dialogContainer.error.handleError(error);
    }
  };

  const handleLogout = () => {
    authContainer.logOut();
    props.navigate!("/login", { replace: true });
  };

  const isAdmin = authContainer.role === "ADMIN";
  const isSuperUser = authContainer.role === "SUPERUSER";
  const isGuest = authContainer.role === "GUEST";
  const isOnline = authContainer.context
    ? authContainer.context.status === "OK"
    : false;
  const statusLabel = isOnline ? "Online" : "Offline";

  const tabItems = isAdmin
    ? [
        { routeName: "approval", label: "Godkendelse" },
        { routeName: "broadcasts", label: "Udsendelser" },
        { routeName: "gallup", label: "Gallup" },
        { routeName: "programs", label: "Programmer" },
        { routeName: "people", label: "Personer" },
        { routeName: "status", label: statusLabel },
      ]
    : isSuperUser
    ? [
        { routeName: "approval", label: "Godkendelse" },
        { routeName: "broadcasts", label: "Udsendelser" },
        { routeName: "gallup", label: "Gallup" },
        { routeName: "programs", label: "Programmer" },
        { routeName: "status", label: statusLabel },
      ]
    : isGuest
    ? [
        { routeName: "broadcasts", label: "Udsendelser" },
        { routeName: "programs", label: "Programmer" },
        { routeName: "people", label: "Personer" },
      ]
    : [
        { routeName: "approval", label: "Godkendelse" },
        { routeName: "status", label: statusLabel },
      ];
  const menuItems = isAdmin
    ? [
        { routeName: "users", label: "Users" },
        { routeName: "positions", label: "Positioner" },
        { routeName: "ignore", label: "Ignorereings liste" },
        { divider: true },
        { routeName: "member", label: "Medlem" },
        { routeName: "member-production-approval", label: "Medlem/Program" },
        { routeName: "member-program-year", label: "Medlem/Program Årstal" },
        {
          routeName: "member-program-comments",
          label: "Medlem/Program Comments",
        },
        { routeName: "member-program-manual", label: "Medlem/Program Kladde" },
        { divider: true },
        { routeName: "broadcast-replay", label: "Start Forfra" },
        { routeName: "broadcast-avu", label: "AVU" },
        { routeName: "broadcast-bonanza", label: "Bonanza" },
        { routeName: "broadcast-copydan", label: "Arkiv" },
        { routeName: "broadcast-import", label: "Importer udsendelser" },
        { divider: true },
        { routeName: "settings", label: "Indstillinger" },
        { onClick: handleAutorun, label: "Autorun" },
        { onClick: handleSyncronize, label: "Syncronize" },
      ]
    : [];
  return (
    <AuthGuard AuthContainer={AuthContainer}>
      <HeaderFlexLayout
        header={
          <LargeTopBar
            development={false}
            logo={
              <Link to="/">
                <img src={logoPNG} style={{ width: 126, height: 30 }} />
              </Link>
            }
            tabItems={tabItems}
            menuItems={menuItems}
            currentRouteName={routeValue}
            onLogOut={handleLogout}
          />
        }
      >
        <Router component={RouterFlexLayout}>
          {isGuest ? (
            <Redirect from="/" to="/broadcasts" noThrow />
          ) : (
            <Redirect from="/" to="/approval" noThrow />
          )}
          {!isGuest && <Approval path="/approval/*" />}
          {(isAdmin || isSuperUser || isGuest) && (
            <Broadcasts path="/broadcasts" />
          )}
          {(isAdmin || isSuperUser) && <Gallup path="/gallup" />}
          {isAdmin && <Ignore path="/ignore" />}
          {(isAdmin || isGuest) && <People path="/people" />}
          {isAdmin && <Positions path="/positions" />}
          {(isAdmin || isSuperUser || isGuest) && <Programs path="/programs" />}
          {isAdmin && <Users path="/users" />}
          {(isAdmin || isSuperUser) && (
            <BroadcastReplay path="/broadcast-replay" />
          )}
          {(isAdmin || isSuperUser) && <BroadcastAvu path="/broadcast-avu" />}
          {(isAdmin || isSuperUser) && (
            <BroadcastBonanza path="/broadcast-bonanza" />
          )}
          {(isAdmin || isSuperUser) && (
            <BroadcastCopydan path="/broadcast-copydan" />
          )}
          {(isAdmin || isSuperUser) && (
            <BroadcastImport path="/broadcast-import" />
          )}
          {isAdmin && <Status path="/status" />}
          {isAdmin && (
            <MemberProductionApproval path="/member-production-approval" />
          )}
          {isAdmin && <MemberProgramYear path="/member-program-year" />}
          {isAdmin && <MemberProgramManual path="/member-program-manual" />}
          {isAdmin && <MemberProgramComments path="/member-program-comments" />}
          {isAdmin && <Member path="/member" />}
          {isAdmin && <SpaceSettings path="/settings" />}
        </Router>
      </HeaderFlexLayout>
    </AuthGuard>
  );
};
