import React from "react";
import { RouteComponentProps } from "@reach/router";

// Containers
import { AuthContainer } from "containers/AuthContainer";

// Components
import { TableContainer } from "@discoverr/ui/components/Table/TableContainer";
import {
  SqlrlWindowDataTable,
  ISqlrlWindowDataTableHandles,
} from "@discoverr/ui/components/Table/SqlrlWindowDataTable";
import {
  SqlrlDetailsModal,
  useSqlrlDetailsModalLink,
} from "@discoverr/ui/components/Modal/SqlrlDetailsModal";
import { SqlrlDetailView } from "@discoverr/ui/components/Detail/SqlrlDetailView";

import { BroadcastGenerelTab } from "components/BroadcastGenerelTab";

// Schema
import {
  BroadcastSchema,
  BroadcastViewSchema,
  ProgramChannelViewSchema,
  ProgramSchema,
} from "schema";
import { ISqlrlSchemaPayload } from "@discoverr/ui/utilities/sqlrl";
import { getFieldMeta } from "@discoverr/ui/components/Formik/MagicFormik";
import { SqlrlFormik } from "@discoverr/ui/components/Formik/SqlrlFormik";
import { SqlrlAutoFormik } from "@discoverr/ui/components/Formik/SqlrlAutoFormik";
import { FormikLookup } from "@discoverr/ui/components/Formik/FormikLookup";
import {
  FormikTextField,
  FormikSelect,
  IFormikSelectOption,
} from "@discoverr/ui/components/Formik";
import { ProgramGenerelTab } from "components/ProgramGenerelTab";
import { ProgramPersonTab } from "components/ProgramPersonTab";
import { BroadcastListTab } from "components/BroadcastListTab";
import { BroadcastReplayListTab } from "components/BroadcastReplayListTab";
import { BroadcastAvuListTab } from "components/BroadcastAvuListTab";
import { ProgramImageTab } from "components/ProgramImageTab";
import { BroadcastBonanzaListTab } from "components/BroadcastBonanzaListTab";
import { BroadcastCopydanListTab } from "components/BroadcastCopydanListTab";

const statusOptions: IFormikSelectOption[] = [
  { value: "AUTO", label: "AUTO" },
  { value: "GODKENDT", label: "GODKENDT" },
  { value: "KLAR", label: "KLAR" },
  { value: "OCR", label: "OCR" },
  { value: "UDSKUDT", label: "UDSKUDT" },
];

//
export interface IBroadcastsProps extends RouteComponentProps {}
export const Broadcasts: React.FunctionComponent<IBroadcastsProps> = (
  props
) => {
  const authContainer = AuthContainer.useContainer();

  const isAdmin = authContainer.role === "ADMIN";
  const isUser = authContainer.role === "USER";
  const isSuperUser = authContainer.role === "SUPERUSER";

  const tableRef =
    React.useRef<ISqlrlWindowDataTableHandles<typeof BroadcastViewSchema>>();

  const [link, handles] = useSqlrlDetailsModalLink<typeof BroadcastViewSchema>();

  const handleRowClick = (
    broadcast: ISqlrlSchemaPayload<typeof BroadcastViewSchema>
  ) => {
    handles.view([{ fieldName: "broadcastId", value: broadcast.broadcastId }]);
  };

  const handleSuccess = () => {
    if (tableRef.current) {
      tableRef.current.refresh();
    }
  };

  const handleBroadcastCreate = () => {
    handles.create();
  };

  return (
    <React.Fragment>
      <TableContainer>
        <SqlrlWindowDataTable
          schema={BroadcastViewSchema}
          title="Udsendelser"
          fields={[
            "startTime",
            "channel",
            "title",
            "seasonNumber",
            "episodeNumber",
            "productionYear",
            "duration",
            "productionCountry",
            "productionNumber",
            "missingCredit",
            "approved",
            "status",
            "genre",
            "programId",
            "broadcastId",
          ]}
          widths={{ title: 300, productionCountry: 140, programId: 200 }}
          onRowClick={handleRowClick}
          contextMenuItems={
            isAdmin
              ? [
                  {
                    label: "Flyt til program",
                    onClick: handleBroadcastCreate,
                  },
                ]
              : []
          }
        />
      </TableContainer>
      <SqlrlDetailsModal
        schema={BroadcastViewSchema}
        fields={["broadcastId", "title", "programId"]}
        link={link}
        caption="Broadcast"
        title="title"
        editable={isAdmin}
        editRenderer={
          isAdmin
            ? ({ data, close, refresh }) => (
                <SqlrlAutoFormik
                  schema={BroadcastSchema}
                  fields={[
                    "programId",
                    "status",
                    "channel",
                    "startTime",
                    "endTime",
                    "title",
                    "subtitle",
                    "duration",
                    "seasonNumber",
                    "episodeNumber",
                    "productionNumber",
                    "productionYear",
                    "productionCountry",
                    "genre",
                    "isReturn",
                    "originalTitle",
                    "seriesTitle",
                    "seasonTitle",
                    "announcedStartTime",
                    "announcedEndTime",
                    "description",
                    "approved",
                  ]}
                  returnFields={[]}
                  fieldRendererMap={{
                    programId: ({ field, form, sqlrlField }) => (
                      <FormikLookup
                        schema={ProgramSchema}
                        fields={[
                          "title",
                          "subtitle",
                          "channel",
                          "genre",
                          "seasonNumber",
                          "episodeNumber",
                          "productionYear",
                          "productionNumber",
                          "programId",
                        ]}
                        filter={[]}
                        valueField="programId"
                        labelField="title"
                        field={field}
                        meta={getFieldMeta(form, field.name)}
                        label={sqlrlField.label}
                      />
                    ),
                    status: ({ field, form, sqlrlField }) => (
                      <FormikSelect
                        field={field}
                        meta={getFieldMeta(form, field.name)}
                        label={sqlrlField.label}
                        options={statusOptions}
                      />
                    ),
                    channel: ({ field, form, sqlrlField }) => (
                      <FormikLookup
                        schema={ProgramChannelViewSchema}
                        fields={["channel"]}
                        filter={[]}
                        valueField="channel"
                        labelField="channel"
                        field={field}
                        meta={getFieldMeta(form, field.name)}
                        label={sqlrlField.label}
                      />
                    ),
                  }}
                  filter={
                    data
                      ? [{ fieldName: "broadcastId", value: data.broadcastId }]
                      : null
                  }
                  initialValues={{}}
                  onSuccess={() => {
                    handleSuccess();
                    refresh && refresh();
                  }}
                  onDelete={close}
                  deletable
                />
              )
            : undefined
        }
        tabs={[
          {
            label: "General",
            renderer: ({ data }) => (
              <BroadcastGenerelTab broadcastId={data.broadcastId} />
            ),
          },
          {
            label: "Program",
            renderer: ({ data }) =>
              data.programId && (
                <ProgramGenerelTab programId={data.programId} />
              ),
          },
          {
            label: "Personer",
            renderer: ({ data }) =>
              data.programId && (
                <ProgramPersonTab data={{ programId: data.programId }} />
              ),
          },
          {
            label: "Udsendelser",
            renderer: ({ data }) =>
              data.programId && <BroadcastListTab programId={data.programId} />,
          },
          {
            label: "AVU",
            renderer: ({ data }) =>
              (isAdmin || isUser || isSuperUser) &&
              data.programId && (
                <BroadcastAvuListTab programId={data.programId} />
              ),
          },
          {
            label: "Bonanza",
            renderer: ({ data }) =>
              (isAdmin || isUser || isSuperUser) &&
              data.programId && (
                <BroadcastBonanzaListTab programId={data.programId} />
              ),
          },
          {
            label: "Copydan",
            renderer: ({ data }) =>
              (isAdmin || isUser || isSuperUser) &&
              data.programId && (
                <BroadcastCopydanListTab programId={data.programId} />
              ),
          },
          {
            label: "Start Forfra",
            renderer: ({ data }) =>
              (isAdmin || isUser || isSuperUser) &&
              data.programId && (
                <BroadcastReplayListTab programId={data.programId} />
              ),
          },
          {
            label: "Billeder",
            renderer: ({ data }) =>
              data.programId && (
                <ProgramImageTab data={{ programId: data.programId }} />
              ),
          },
        ]}
      />
    </React.Fragment>
  );
};
