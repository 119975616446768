import React from "react";

import { MemberProgramSchema } from "schema";

import { DialogContainer } from "@discoverr/ui/containers/DialogContainer";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";

const initialValue = {
  applicationFlowTvFunds: false,
  applicationFlowTvSff: false,
  applicationAvuFunds: false,
  applicationDrArchiveFlow: false,
  applicationDrArchiveClip: false,
  applicationDrAcrhiveDrDk: false,
  applicationDrArchiveDrDkClip: false,
  applicationBonanza: false,
};

//
export interface IApplicationDialogProps {
  filter: any[] | null;
  open: boolean;
  onClose: () => void;
}
export const ApplicationPoolDialog = (props: IApplicationDialogProps) => {
  const dialogContainer = DialogContainer.useContainer();

  const [values, setValues] =
    React.useState<Record<string, boolean>>(initialValue);

  const handleEnter = async () => {
    const res = await MemberProgramSchema.select({
      fields: [
        "applicationFlowTvFunds",
        "applicationFlowTvSff",
        "applicationAvuFunds",
        "applicationDrArchiveFlow",
        "applicationDrArchiveClip",
        "applicationDrAcrhiveDrDk",
        "applicationDrArchiveDrDkClip",
        "applicationBonanza",
      ],
      filter: props.filter,
    });
    if (res.data && res.data[0]) {
      setValues(res.data[0]);
    }
  };

  const handleExited = () => {
    setValues(initialValue);
  };

  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setValues((values) => ({
      ...values,
      [e.currentTarget.name]: checked,
    }));
  };

  const handleSave = () => {
    MemberProgramSchema.update(values, {
      filter: props.filter,
    })
      .then(() => {
        props.onClose();
      })
      .catch(dialogContainer.error.handleError);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      onEnter={handleEnter}
      onExited={handleExited}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>Ansøgningspuljer</DialogTitle>
      <Divider />
      <TableContainer>
        <Table aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                rowSpan={2}
                style={{ width: 150, borderRight: "1px solid #ddd" }}
              >
                Puljer
              </TableCell>
              <TableCell
                align="center"
                colSpan={2}
                style={{ borderRight: "1px solid #ddd" }}
              >
                Flow TV
              </TableCell>
              <TableCell
                align="center"
                style={{ borderRight: "1px solid #ddd" }}
              >
                AVU
              </TableCell>
              <TableCell
                align="center"
                colSpan={4}
                style={{ borderRight: "1px solid #ddd" }}
              >
                DR Arkiv
              </TableCell>
              <TableCell align="center">Bonanza</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                style={{ borderRight: "1px solid #ddd" }}
              >
                TV-midler
              </TableCell>
              <TableCell
                align="center"
                style={{ borderRight: "1px solid #ddd" }}
              >
                Start Forfra
              </TableCell>
              <TableCell
                align="center"
                style={{ borderRight: "1px solid #ddd" }}
              >
                AVU-Medier.
              </TableCell>
              <TableCell
                align="center"
                style={{ borderRight: "1px solid #ddd" }}
              >
                DR Flow tv
              </TableCell>
              <TableCell
                align="center"
                style={{ borderRight: "1px solid #ddd" }}
              >
                DR Flow tv klip
              </TableCell>
              <TableCell
                align="center"
                style={{ borderRight: "1px solid #ddd" }}
              >
                OD - DR.DK
              </TableCell>
              <TableCell
                align="center"
                style={{ borderRight: "1px solid #ddd" }}
              >
                OD - DR.DK klip
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                colSpan={1}
                style={{ borderRight: "1px solid #ddd" }}
              >
                Hvem kan søge
              </TableCell>
              <TableCell
                align="center"
                colSpan={2}
                style={{ borderRight: "1px solid #ddd" }}
              >
                Freelancer med Copydan forbehold
              </TableCell>
              <TableCell
                align="center"
                colSpan={1}
                style={{ borderRight: "1px solid #ddd" }}
              >
                Freelancer fastansætte
              </TableCell>
              <TableCell
                align="center"
                colSpan={4}
                style={{ borderRight: "1px solid #ddd" }}
              >
                Freelancer
                <br />
                Fastansat ansat før 1/1-2013
                <br />
                Produktioner produceret før 1/1-2013
              </TableCell>
              <TableCell align="center" colSpan={1}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                align="center"
                style={{ borderRight: "1px solid #ddd" }}
              >
                Hvilke puljer kan de få midler fra (sæt kryds)
              </TableCell>
              <TableCell
                align="center"
                padding="checkbox"
                style={{ borderRight: "1px solid #ddd" }}
              >
                <Checkbox
                  name="applicationFlowTvFunds"
                  checked={values["applicationFlowTvFunds"]}
                  onChange={handleValueChange}
                />
              </TableCell>
              <TableCell
                align="center"
                padding="checkbox"
                style={{ borderRight: "1px solid #ddd" }}
              >
                <Checkbox
                  name="applicationFlowTvSff"
                  checked={values["applicationFlowTvSff"]}
                  onChange={handleValueChange}
                />
              </TableCell>

              <TableCell
                align="center"
                padding="checkbox"
                style={{ borderRight: "1px solid #ddd" }}
              >
                <Checkbox
                  name="applicationAvuFunds"
                  checked={values["applicationAvuFunds"]}
                  onChange={handleValueChange}
                />
              </TableCell>

              <TableCell
                align="center"
                padding="checkbox"
                style={{ borderRight: "1px solid #ddd" }}
              >
                <Checkbox
                  name="applicationDrArchiveFlow"
                  checked={values["applicationDrArchiveFlow"]}
                  onChange={handleValueChange}
                />
              </TableCell>

              <TableCell
                align="center"
                padding="checkbox"
                style={{ borderRight: "1px solid #ddd" }}
              >
                <Checkbox
                  name="applicationDrArchiveClip"
                  checked={values["applicationDrArchiveClip"]}
                  onChange={handleValueChange}
                />
              </TableCell>

              <TableCell
                align="center"
                padding="checkbox"
                style={{ borderRight: "1px solid #ddd" }}
              >
                <Checkbox
                  name="applicationDrAcrhiveDrDk"
                  checked={values["applicationDrAcrhiveDrDk"]}
                  onChange={handleValueChange}
                />
              </TableCell>

              <TableCell
                align="center"
                padding="checkbox"
                style={{ borderRight: "1px solid #ddd" }}
              >
                <Checkbox
                  name="applicationDrArchiveDrDkClip"
                  checked={values["applicationDrArchiveDrDkClip"]}
                  onChange={handleValueChange}
                />
              </TableCell>

              <TableCell align="center" padding="checkbox">
                <Checkbox
                  name="applicationBonanza"
                  checked={values["applicationBonanza"]}
                  onChange={handleValueChange}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <DialogActions>
        <Button color="primary" onClick={props.onClose}>
          Annuller
        </Button>
        <Button color="primary" onClick={handleSave}>
          Gem
        </Button>
      </DialogActions>
    </Dialog>
  );
};
