import React from "react";
import { RouteComponentProps } from "@reach/router";

// Containers
import { AuthContainer } from "containers/AuthContainer";

// Components
import { SqlrlLogin } from "@discoverr/ui/components/View/SqlrlLogin";

// Assets
const logoPNG = require("assets/logo_dark.png");

//
export interface ILoginProps extends RouteComponentProps {}
export const Login: React.FunctionComponent<ILoginProps> = props => {
  const handleLoginSuccess = () => {
    if (props.location!.state && props.location!.state.backTo) {
      props.navigate!(props.location!.state.backTo, {});
    } else {
      props.navigate!("/");
    }
  };
  return (
    <SqlrlLogin
      logo={<img src={logoPNG} />}
      AuthContainer={AuthContainer}
      onLoginSuccess={handleLoginSuccess}
    />
  );
};
