import React, { useContext, useRef, useEffect, useState } from "react";
import { observer, Observer } from "mobx-react-lite";
import { RouteComponentProps, navigate } from "@reach/router";
import { makeStyles } from "@material-ui/styles";
import { FixedSizeGrid } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

// Backend
import { backend } from "backend";

// Schema
import {
  BroadcastSchema,
  IgnoreBroadcastSchema,
  BroadcastApprovalDetailViewSchema,
  ProgramChannelViewSchema,
  PersonSchema,
} from "schema";

// Containers
import { DialogContainer } from "@discoverr/ui/containers/DialogContainer";
import { AuthContainer } from "containers/AuthContainer";

// Utilities
import { getFilterFromObject } from "@discoverr/ui/utilities/sqlrl";
import { useHotkeys } from "@discoverr/ui/utilities/keyboard";

// Stores
import { ViewStore, IFrameModel } from "./ViewStore";
import { createProgram as ocrCreateProgramEndpoint } from "../Ocr/ViewStore";

// Api
//import { joinProgram } from "src/api/program";

// Components
import {
  Box,
  Typography,
  Divider,
  Card,
  CardContent,
  Grid,
  Theme,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Icon,
} from "@material-ui/core";
import {
  MagicFormik,
  FormikTextField,
  getFieldMeta,
  FormikDatePicker,
  FormikLookup,
  FormikSelect,
  IFormikSelectOption,
} from "@discoverr/ui/components/Formik";
import {
  SqlrlDetailView,
  ISqlrlDetailViewHandles,
} from "@discoverr/ui/components/Detail/SqlrlDetailView";
import {
  SqlrlFormikModal,
  useSqlrlFormikModalLink,
} from "@discoverr/ui/components/Modal/SqlrlFormikModal";

import { ProgramReferalModal } from "./components/ProgramReferalModal";
import {
  SqlrlDetailsModal,
  useSqlrlDetailsModalLink,
} from "@discoverr/ui/components/Modal/SqlrlDetailsModal";
import { SqlrlAutoFormik } from "@discoverr/ui/components/Formik/SqlrlAutoFormik";
import { GallupReferalModal } from "./components/GallupReferalModal";

// import { IgnoreBroadcastModal } from "./components/IgnoreBroadcastModal";
// import { ProgramLookupModal } from "./components/ProgramLookupModal";

// Constants
const statusOptions: IFormikSelectOption[] = [
  { value: "AUTO", label: "AUTO" },
  { value: "GODKENDT", label: "GODKENDT" },
  { value: "KLAR", label: "KLAR" },
  { value: "OCR", label: "OCR" },
  { value: "UDSKUDT", label: "UDSKUDT" },
];

// Hooks
const useStyles = makeStyles((theme: Theme) => ({
  durationBar: {
    position: "relative",
    marginTop: 8,
    height: 8,
    width: "100%",
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: 4,
    border: "1px solid #ddd",
  },
  durationInterval: {
    position: "absolute",
    borderRadius: 4,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.secondary.main,
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageButton: {
    position: "relative",
    border: "none",
    padding: 8,
    marign: 0,
    width: "100%",
    outline: "none",
    display: "flex",
  },
  imageSelectedOverlay: {
    position: "absolute",
    top: 8,
    right: 8,
    bottom: 8,
    left: 8,
    borderRadius: 4,
    backgroundColor: theme.palette.secondary.light,
    opacity: 0.8,
    "&::before": {
      color: "#fff",
      content: "'\\E876'",
      fontFamily: "Material Icons",
      fontSize: 48,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -20,
      marginLeft: -20,
    },
  },
}));

//
export interface IFramesProps extends RouteComponentProps {
  broadcastId?: string;
}
export const Frames: React.SFC<IFramesProps> = observer((props) => {
  const [anchorEl, updateAnchorEl] = useState<HTMLElement | null>(null);
  const dialogContainer = DialogContainer.useContainer();
  const authContainer = AuthContainer.useContainer();
  const { current: viewStore } = useRef(new ViewStore());
  const detailViewRef =
    React.useRef<
      ISqlrlDetailViewHandles<typeof BroadcastApprovalDetailViewSchema>
    >();

  const [ignoreLink, ignoreHandler] =
    useSqlrlFormikModalLink<typeof IgnoreBroadcastSchema>();

  const [link, handles] = useSqlrlDetailsModalLink<typeof BroadcastSchema>();

  const [programReferalOpen, setProgramReferalOpen] = React.useState(false);
  const [gallupReferalOpen, setGallupReferalOpen] = React.useState(false);

  useEffect(() => {
    if (props.broadcastId) {
      viewStore.initRecord(props.broadcastId);
    }
  }, []);

  useHotkeys(
    "*",
    (event, handler) => {
      if (event.key === "Shift") {
        if (event.type === "keyup") {
          viewStore.disableMultiSelect();
        }
        if (event.type === "keydown") {
          viewStore.enableMultiSelect();
        }
      }
    },
    { scope: "all", keyup: true }
  );

  const classes = useStyles();

  const broadcast = viewStore.broadcast;
  const duration = 1682;
  const frames = viewStore.frames;

  const handleClose = () => {
    updateAnchorEl(null);
  };

  const handleProgramReferealClose = () => {
    setProgramReferalOpen(false);
  };

  const handlePostpone = () => {
    if (viewStore.broadcast && viewStore.broadcast.broadcastId) {
      dialogContainer.textField.create({
        title: "Udskyd",
        message: "Tilføj en kommentar",
        onSubmit: async (value) => {
          try {
            if (viewStore.broadcast) {
              await BroadcastSchema.update(
                {
                  broadcastId: viewStore.broadcast.broadcastId,
                  comment: value,
                  status: "UDSKUDT",
                },
                {
                  filter: [
                    {
                      fieldName: "broadcastId",
                      value: viewStore.broadcast.broadcastId,
                    },
                  ],
                }
              );
              navigate("/approval");
            }
          } catch (error) {
            dialogContainer.error.handleError(error);
          }
          handleClose();
        },
      });
    }
  };

  const handleTempApprove = async () => {
    if (viewStore.broadcast) {
      try {
        await BroadcastSchema.update(
          {
            approved: true,
          },
          {
            filter: [
              {
                fieldName: "broadcastId",
                value: viewStore.broadcast.broadcastId,
              },
            ],
          }
        );
        handleClose();
        navigate("/approval");
      } catch (error) {
        dialogContainer.error.handleError(error);
      }
    }
  };

  const handleIgnore = () => {
    ignoreHandler.create();
    handleClose();
  };

  const handleSelectProgram = () => {
    setProgramReferalOpen(true);
    handleClose();
  };

  const handleSelectGallupProgram = () => {
    setGallupReferalOpen(true);
    handleClose();
  };

  const handleGallupProgramClose = () => {
    setGallupReferalOpen(false);
  };

  const handleGallupProgramSelect = (item: {
    title: string;
    gallupId: string;
  }) => {
    dialogContainer.confirm.create({
      title: "Bekræft reference",
      message:
        'Ved godkendelse bliver "' +
        item.title +
        '" tilknyttet det valgte Gallup program.',
      onConfirm: async () => {
        try {
          if (props.broadcastId) {
            await backend.get<IFrameModel[]>(
              "/sqlrl/custom/sp_broadcast_gallup_join",
              {
                params: {
                  broadcastId: props.broadcastId!,
                  gallupId: item.gallupId,
                },
              }
            );
            if (detailViewRef.current) {
              detailViewRef.current.refresh();
            }
            if (props.broadcastId) {
              viewStore.initRecord(props.broadcastId);
            }
            handleGallupProgramClose();
          } else {
            throw "Missing programId";
          }
        } catch (error) {
          dialogContainer.error.handleError(error);
        }
      },
    });
  };

  const handleProgramSelect = (item: {
    title: string | null;
    programId: string;
  }) => {
    dialogContainer.confirm.create({
      title: "Bekræft reference",
      message:
        'Ved godkendelse bliver "' +
        item.title +
        '" tilknyttet det valgte program.',
      onConfirm: async () => {
        try {
          if (props.broadcastId) {
            await backend.get<IFrameModel[]>(
              "/sqlrl/custom/sp_program_join_broadcast",
              {
                params: {
                  broadcastId: props.broadcastId!,
                  programId: item.programId,
                },
              }
            );

            handleClose();
            navigate("/approval");
          } else {
            throw "Missing programId";
          }
        } catch (error) {
          dialogContainer.error.handleError(error);
        }
      },
    });
  };

  const handleEdit = () => {
    if (broadcast) {
      handles.update([
        { fieldName: "broadcastId", value: broadcast.broadcastId },
      ]);
      handleClose();
    }
  };

  const handleContinue = async () => {
    if (props.broadcastId) {
      dialogContainer.loading.start({
        title: "Analyser billeder",
        message: "Dette kan tage et øjeblik, vent venligst...",
      });
      try {
        await viewStore.setSelectedFrames(props.broadcastId);
      } catch (error) {
        dialogContainer.error.handleError(error);
      }
      dialogContainer.loading.stop();

      props.navigate!("../ocr");
    }
  };

  const cellRenderer = ({ columnIndex, key, rowIndex, style }: any) => {
    if (!frames) {
      return null;
    }
    const index = rowIndex * 4 + columnIndex;
    if (!(frames.length > index)) {
      return null;
    }
    const data = frames[rowIndex * 4 + columnIndex];
    return (
      <div key={key} style={style}>
        <Observer>
          {() => (
            <button
              className={classes.imageButton}
              onClick={viewStore.handleFrameToggle(data)}
            >
              <img style={{ width: "100%", borderRadius: 8 }} src={data.path} />
              {data.selected && (
                <span className={classes.imageSelectedOverlay} />
              )}
            </button>
          )}
        </Observer>
      </div>
    );
  };

  const createProgram = () => {
    dialogContainer.confirm.create({
      title: "Bekræft oprettelse",
      message: "Bekræft oprettelse af program uden afløftning",
      onConfirm: () => {
        backend
          .post<[]>(
            "broadcast.approval.createProgram",
            {},
            {
              params: { broadcastId: props.broadcastId },
            }
          )
          .then(() => {
            navigate("/approval");
          })
          .catch(dialogContainer.error.handleError);
      },
    });
  };

  const copyCredits = () => {
    dialogContainer.textField.create({
      title: "Kopier Krediteringer",
      closeText: "Annuller",
      submitText: "Opret Program",
      message:
        "Indtast programId for programmet du ønsker at kopier krediteringer fra.",
      onSubmit: (value) => {
        if (value) {
          PersonSchema.select({
            fields: ["fileName", "name", "role"],
            filter: [{ fieldName: "programId", value }],
          })
            .then((res) => {
              if (res.data.length > 0 && props.broadcastId) {
                dialogContainer.loading.start({
                  title: "Program Oprettes",
                  message: "Vent venligst...",
                });
                ocrCreateProgramEndpoint({
                  broadcastId: props.broadcastId,
                  data: res.data,
                })
                  .then(() => navigate("/approval"))
                  .catch(dialogContainer.error.handleError)
                  .finally(() => dialogContainer.loading.stop());
              }
            })
            .catch(dialogContainer.error.handleError);
        }
      },
    });
  };

  if (!broadcast) {
    return null;
  }

  return (
    <Box p={2}>
      <Card>
        <CardContent
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h5" component="h2">
              {broadcast.title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {broadcast.comment
                ? `Kommentar: ${broadcast.comment})`
                : "Ingen kommentar"}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton
              style={{ marginRight: 16 }}
              color="primary"
              onClick={(event) => {
                updateAnchorEl(event.currentTarget);
              }}
            >
              <Icon>more_vert</Icon>
            </IconButton>
            <Menu
              id="render-props-menu"
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <MenuItem onClick={handlePostpone}>Udskyd</MenuItem>
              <MenuItem onClick={handleIgnore}>Ignorere</MenuItem>
              <MenuItem onClick={handleTempApprove}>
                Midlertidig Godkend
              </MenuItem>
              <MenuItem onClick={handleSelectProgram}>
                Refererer Program
              </MenuItem>
              <MenuItem onClick={handleSelectGallupProgram}>
                Refererer Gallup Program
              </MenuItem>
              <MenuItem onClick={handleEdit}>Rediger</MenuItem>
              <MenuItem onClick={createProgram}>Opret Program</MenuItem>
              <MenuItem onClick={copyCredits}>Kopier Krediteringer</MenuItem>
            </Menu>
          </div>
        </CardContent>
        <Divider />
        <CardContent>
          <SqlrlDetailView
            innerRef={detailViewRef}
            schema={BroadcastApprovalDetailViewSchema}
            fields={[
              "startTime",
              "endTime",
              "duration",
              "channel",
              "seasonNumber",
              "episodeNumber",
              "subtitle",
              "seasonTitle",
              "seriesTitle",
              "genre",
              "originalTitle",
              "productionNumber",
              "productionYear",
              "productionCountry",
              "status",
              "announcedStartTime",
              "announcedEndTime",
              "gallupTitle",
              "gallupId",
              "broadcastId",
            ]}
            filter={[
              { fieldName: "broadcastId", value: broadcast.broadcastId },
            ]}
            xs={3}
          ></SqlrlDetailView>
        </CardContent>
      </Card>
      <Card style={{ marginTop: 16 }}>
        <MagicFormik
          initialValues={{
            startOffset: 0,
            startDuration: 0,
            endOffset: 0,
            endDuration: 120,
          }}
          onSubmit={async (values) => {
            console.log(values);
            if (props.broadcastId) {
              dialogContainer.loading.start({
                title: "Henter billeder",
                message: "Dette kan tage et øjeblik, vent venligst...",
              });
              try {
                await viewStore.createFrames({
                  broadcastId: props.broadcastId,
                  ...values,
                });
              } catch (error) {
                dialogContainer.error.handleError(error);
              } finally {
                dialogContainer.loading.stop();
              }
            }
          }}
        >
          {({ Field, values, submitForm, handleSubmit }) => (
            <CardContent>
              <form onSubmit={handleSubmit}>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={1}>
                    <Field name="startOffset">
                      {({ field, form }) => (
                        <FormikTextField
                          field={field}
                          meta={getFieldMeta(form, field.name)}
                          label="Forsinkelse"
                          type="number"
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item={true} xs={2}>
                    <Field name="startDuration">
                      {({ field, form }) => (
                        <FormikTextField
                          field={field}
                          meta={getFieldMeta(form, field.name)}
                          label="Varighed"
                          type="number"
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item={true} xs={6} className={classes.flexCenter}>
                    <Button
                      variant="contained"
                      color="default"
                      onClick={submitForm}
                    >
                      Hent billeder
                    </Button>
                  </Grid>
                  <Grid item={true} xs={2}>
                    <Field name="endDuration">
                      {({ field, form }) => (
                        <FormikTextField
                          field={field}
                          meta={getFieldMeta(form, field.name)}
                          label="Varighed"
                          type="number"
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item={true} xs={1}>
                    <Field name="endOffset">
                      {({ field, form }) => (
                        <FormikTextField
                          field={field}
                          meta={getFieldMeta(form, field.name)}
                          label="Forsinkelse"
                          type="number"
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
              </form>
              <div className={classes.durationBar}>
                <span
                  className={classes.durationInterval}
                  style={{
                    left: `${(values.startOffset / duration) * 100}%`,
                    width: `${(values.startDuration / duration) * 100}%`,
                  }}
                />
                <span
                  className={classes.durationInterval}
                  style={{
                    right: `${(values.endOffset / duration) * 100}%`,
                    width: `${(values.endDuration / duration) * 100}%`,
                  }}
                />
              </div>
            </CardContent>
          )}
        </MagicFormik>
        {frames ? (
          <React.Fragment>
            <Divider />
            <AutoSizer disableHeight>
              {({ width }) => (
                <FixedSizeGrid
                  columnCount={4}
                  columnWidth={width / 4}
                  height={500}
                  rowCount={Math.ceil(frames.length / 4)}
                  rowHeight={Math.ceil((9 / 16) * (width / 4) + 8)}
                  width={width}
                >
                  {cellRenderer}
                </FixedSizeGrid>
              )}
            </AutoSizer>
            <Divider />
            <CardContent style={{ textAlign: "right" }}>
              {viewStore.broadcast && viewStore.broadcast.status === "OCR" && (
                <Button
                  variant="contained"
                  color="default"
                  style={{ marginRight: 16 }}
                  onClick={() => props.navigate!(`../ocr-review`)}
                >
                  Fortsæt
                </Button>
              )}
              <Button
                variant="contained"
                color="default"
                onClick={handleContinue}
              >
                Analyser billeder
              </Button>
            </CardContent>
          </React.Fragment>
        ) : null}
      </Card>
      <SqlrlFormikModal
        schema={IgnoreBroadcastSchema}
        fields={["title", "productionNumber"]}
        returnFields={[]}
        link={ignoreLink}
        initialValues={{
          title:
            viewStore.broadcast && viewStore.broadcast.title
              ? viewStore.broadcast.title
              : "",
          productionNumber:
            viewStore.broadcast && viewStore.broadcast.productionNumber
              ? viewStore.broadcast.productionNumber
              : undefined,
        }}
        title="Ignorere udsendelse"
        onSuccess={() => navigate("/approval")}
      >
        {({ SqlrlField }) => (
          <Box>
            <SqlrlField name="title">
              {(props, schemaField) => (
                <FormikTextField
                  field={props.field}
                  meta={getFieldMeta(props.form, props.field.name)}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label={schemaField.label}
                  autoFocus
                />
              )}
            </SqlrlField>
            <SqlrlField name="productionNumber">
              {(props, schemaField) => (
                <FormikTextField
                  field={props.field}
                  meta={getFieldMeta(props.form, props.field.name)}
                  variant="outlined"
                  margin="normal"
                  type="number"
                  fullWidth
                  label={schemaField.label}
                />
              )}
            </SqlrlField>
          </Box>
        )}
      </SqlrlFormikModal>
      <ProgramReferalModal
        open={programReferalOpen}
        onClose={handleProgramReferealClose}
        onSelect={handleProgramSelect}
      />

      <GallupReferalModal
        open={gallupReferalOpen}
        onClose={handleGallupProgramClose}
        onSelect={handleGallupProgramSelect}
      />
      <SqlrlDetailsModal
        schema={BroadcastSchema}
        fields={["broadcastId", "title"]}
        link={link}
        caption="Broadcast"
        title="title"
        editable={true}
        editRenderer={({ data, close, refresh }) => (
          <SqlrlAutoFormik
            schema={BroadcastSchema}
            fields={[
              "programId",
              "status",
              "channel",
              "startTime",
              "endTime",
              "title",
              "subtitle",
              "duration",
              "seasonNumber",
              "episodeNumber",
              "productionNumber",
              "productionYear",
              "productionCountry",
              "genre",
              "isReturn",
              "originalTitle",
              "seriesTitle",
              "seasonTitle",
              "announcedStartTime",
              "announcedEndTime",
              "description",
              "approved",
            ]}
            returnFields={[]}
            fieldRendererMap={{
              programId: ({ field, form, sqlrlField }) => (
                <FormikLookup
                  schema={ProgramChannelViewSchema}
                  fields={["channel"]}
                  filter={[]}
                  valueField="channel"
                  labelField="channel"
                  field={field}
                  meta={getFieldMeta(form, field.name)}
                  label={sqlrlField.label}
                />
              ),
              status: ({ field, form, sqlrlField }) => (
                <FormikSelect
                  field={field}
                  meta={getFieldMeta(form, field.name)}
                  label={sqlrlField.label}
                  options={statusOptions}
                />
              ),
              channel: ({ field, form, sqlrlField }) => (
                <FormikLookup
                  schema={ProgramChannelViewSchema}
                  fields={["channel"]}
                  filter={[]}
                  valueField="channel"
                  labelField="channel"
                  field={field}
                  meta={getFieldMeta(form, field.name)}
                  label={sqlrlField.label}
                />
              ),
            }}
            filter={
              data
                ? [{ fieldName: "broadcastId", value: data.broadcastId }]
                : null
            }
            initialValues={{}}
            onSuccess={() => {
              if (props.broadcastId) {
                viewStore.initRecord(props.broadcastId);
              }
            }}
            deletable={authContainer.role === "ADMIN"}
            onDelete={() => {
              close();
              navigate("/approval");
            }}
          />
        )}
        tabs={[
          {
            label: "General",
            renderer: ({ data }) => (
              <Box p={3}>
                <SqlrlDetailView
                  schema={BroadcastSchema}
                  fields={[
                    "startTime",
                    "endTime",
                    "channel",
                    "title",
                    "subtitle",
                    "duration",
                    "seasonNumber",
                    "episodeNumber",
                    "productionNumber",
                    "productionYear",
                    "productionCountry",
                    "genre",
                    "isReturn",
                    "originalTitle",
                    "seriesTitle",
                    "announcedStartTime",
                    "announcedEndTime",
                    "description",
                  ]}
                  filter={[
                    {
                      fieldName: "broadcastId",
                      value: data.broadcastId,
                    },
                  ]}
                  title="Service Provider"
                />
              </Box>
            ),
          },
        ]}
      />
    </Box>
  );
});
