import React from "react";
import { FileStatus } from "filepond";
import AnimateHeight from "react-animate-height";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import { FileUploader } from "components/FileUploader";

//
export interface IFileUploadDialogProps {
  path: string;
  open: boolean;
  onClose: () => void;
}
export const FileUploadDialog = (props: IFileUploadDialogProps) => {
  const [files, setFiles] = React.useState<any[]>([]);
  const handleExited = () => {
    setFiles([]);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      onExited={handleExited}
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">Upload dokument</DialogTitle>
      <DialogContent>
        <AnimateHeight style={{}} height={76}>
          <FileUploader
            path={props.path}
            files={files}
            allowRevert={false}
            onupdatefiles={(fileItems) => {
              if (
                fileItems[0] &&
                fileItems[0].status === FileStatus.PROCESSING_COMPLETE
              ) {
                setTimeout(props.onClose, 500);
              }
              setFiles(fileItems.map((fileItem) => fileItem.file));
            }}
            params={{}}
          ></FileUploader>
        </AnimateHeight>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={props.onClose}>
          Luk
        </Button>
      </DialogActions>
    </Dialog>
  );
};
