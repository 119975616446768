import React from "react";
import { RouteComponentProps } from "@reach/router";

// Backend
import { baseURL, backend } from "backend";

// Containers
import { AuthContainer } from "containers/AuthContainer";
import { DialogContainer } from "@discoverr/ui/containers/DialogContainer";
import {
  getAxioscConfigFromSqlrlSelect,
  getFilterFromObject,
} from "@discoverr/ui/utilities/sqlrl";

import { IRadioGroupDialogOption } from "@discoverr/ui/components/Dialog/RadioGroupDialog";

// Components
import Box from "@material-ui/core/Box";

import { TableContainer } from "@discoverr/ui/components/Table/TableContainer";
import {
  SqlrlWindowDataTable,
  ISqlrlWindowDataTableHandles,
} from "@discoverr/ui/components/Table/SqlrlWindowDataTable";
import {
  SqlrlDetailsModal,
  useSqlrlDetailsModalLink,
} from "@discoverr/ui/components/Modal/SqlrlDetailsModal";
import { SqlrlDetailView } from "@discoverr/ui/components/Detail/SqlrlDetailView";

// Schema
import {
  MemberProgramViewSchema,
  MemberProgramSchema,
  BroadcastSchema,
  MemberSchema,
  MemberContractLinkViewSchema,
  IMemberContractLinkViewSchemaPayload,
  MemberProgramCommentViewSchema,
  IMemberProgramCommentViewSchemaPayload,
  MemberProgramCommentSchema,
  UserSchema,
  PositionSchema,
  PersonSchema,
} from "schema";
import { ISqlrlSchemaPayload } from "@discoverr/ui/utilities/sqlrl";
import { SqlrlAutoFormik } from "@discoverr/ui/components/Formik/SqlrlAutoFormik";
import {
  FormikTextField,
  IFormikSelectOption,
  getFieldMeta,
  FormikSelect,
  FormikSwitch,
  FormikLookup,
} from "@discoverr/ui/components/Formik";
import { JobFunctionModal } from "./components/JobFunctionModal";
import { ApplicationPoolDialog } from "components/ApplicationPoolDialog";

// Constants
const statusOptions: (IRadioGroupDialogOption & IFormikSelectOption)[] = [
  { value: "KLAR", label: "KLAR" },
  { value: "INDSENDT", label: "INDSENDT" },
  { value: "GODKENDT", label: "GODKENDT" },
  { value: "AFVIST", label: "AFVIST" },
  { value: "UDBETALT", label: "UDBETALT" },
  { value: "AFVENTER TILBAGEMELDING", label: "AFVENTER TILBAGEMELDING" },
  { value: "MANGLER COPYDAN FORBEHOLD", label: "MANGLER COPYDAN FORBEHOLD" },
  { value: "MANGLER KONTRAKT", label: "MANGLER KONTRAKT" },
  { value: "Søgt i særordning", label: "Søgt i særordning" },
];

const recruitmentFormOptions: (IRadioGroupDialogOption &
  IFormikSelectOption)[] = [
  { value: "Fastansat DR/TV2", label: "Fastansat DR/TV2" },
  {
    value: "Fastansat Produktionsselskab",
    label: "Fastansat Produktionsselskab",
  },
  { value: "TBA Ansat DR/TV2", label: "TBA Ansat DR/TV2" },
  {
    value: "TBA Ansat Produktionsselskab",
    label: "TBA Ansat Produktionsselskab",
  },
  { value: "Freelance", label: "Freelance" },
  { value: "Selvstændig", label: "Selvstændig" },
  {
    value: "Tilknyttede tekstningsbureau",
    label: "Tilknyttede tekstningsbureau",
  },
];

//
export interface IMemberProductionApprovalProps extends RouteComponentProps {}
export const MemberProductionApproval: React.FunctionComponent<IMemberProductionApprovalProps> =
  (props) => {
    const dialogContainer = DialogContainer.useContainer();
    const authContainer = AuthContainer.useContainer();

    const tableRef =
      React.useRef<
        ISqlrlWindowDataTableHandles<typeof MemberProgramViewSchema>
      >();
    const commentTableRef =
      React.useRef<
        ISqlrlWindowDataTableHandles<typeof MemberProgramViewSchema>
      >();
    const [link, handles] =
      useSqlrlDetailsModalLink<typeof MemberProgramViewSchema>();

    const [jobFunctionModalOpen, setJobFunctionModalOpen] =
      React.useState(false);

    const [applicationPoolDialogOpen, setApplicationPoolDialogOpen] =
      React.useState<{ filter: any[] | null; open: boolean }>({
        filter: null,
        open: false,
      });

    const handleRowClick = (
      person: ISqlrlSchemaPayload<typeof MemberProgramViewSchema>
    ) => {
      handles.view([
        { fieldName: "programMemberId", value: person.programMemberId },
      ]);
    };

    const handleShowSent = () => {
      if (tableRef.current) {
        tableRef.current.setFilter(
          {
            status: "INDSENDT",
          },
          true
        );
      }
    };

    const handleShowAll = () => {
      if (tableRef.current) {
        tableRef.current.setFilter(
          {
            status: "",
          },
          true
        );
      }
    };

    const handleContractClick = (
      row: Pick<IMemberContractLinkViewSchemaPayload, "memberContractLinkId">
    ) => {
      window.open(
        baseURL +
          "member.contract.download?memberContractLinkId=" +
          row.memberContractLinkId,
        "_blank"
      );
    };

    const handleCommentClick = (
      row: Pick<IMemberProgramCommentViewSchemaPayload, "comment">
    ) => {
      dialogContainer.alert.create({
        title: "Kommentar",
        message: row.comment,
      });
    };

    const handleCommentCreate = (programMemberId: string) => {
      dialogContainer.textField.create({
        title: "Opret kommentar",
        message: "Skriv en kommentar.",
        label: "Kommentar",
        onSubmit: (value) => {
          if (value && authContainer.authId) {
            MemberProgramCommentSchema.insert({
              programMemberId: programMemberId,
              userId: authContainer.authId,
              comment: value,
            })
              .then(() => {
                commentTableRef.current && commentTableRef.current.refresh();
              })
              .catch((error) => {
                dialogContainer.error.handleError(error);
              });
          }
        },
      });
    };

    const handleApprove = (filter: any[]) => {
      dialogContainer.radioGroup.create({
        title: "Godkend/afvis",
        options: statusOptions,
        submitText: "Vælg",
        closeText: "Annuller",
        onSubmit: (value) => {
          if (value) {
            MemberProgramViewSchema.update(
              {
                status: value.value,
                approvedDate: new Date().toISOString(),
                approvedBy: authContainer.authId,
              },
              {
                filter,
              }
            ).then(() => {
              handles.close();
              if (tableRef.current) {
                tableRef.current.refresh();
              }
            });
          }
        },
      });
    };

    const handleApproveTable = () => {
      if (tableRef.current) {
        const filter = getFilterFromObject(tableRef.current.getFilterValues(), {
          wildcard: true,
        });
        if (filter.length > 0) {
          handleApprove(filter);
        } else {
          dialogContainer.confirm.create({
            title: "Ingen filter sat",
            message: "Tilføj venlist et eller flere filtere og prøv igen.",
            closeText: "Luk",
            confirmText: "OK",
          });
        }
      }
    };

    const handleApproveDetail = () => {
      if (link && link.filter) {
        handleApprove([
          { fieldName: "programMemberId", value: link.filter[0].value },
        ]);
      }
    };

    const handleApproveWithDefault = () => {
      dialogContainer.confirm.create({
        title: "Bekræft godkendelse",
        message: "Klik 'Godkend' for at godkende denne.",
        confirmText: "Godkend",
        closeText: "Annuller",
        onConfirm: () => {
          if (link && link.filter) {
            MemberProgramSchema.update(
              {
                status: "GODKENDT",
                approvedDate: new Date().toISOString(),
                approvedBy: authContainer.authId,
              },
              {
                filter: [
                  { fieldName: "programMemberId", value: link.filter[0].value },
                ],
              }
            ).then(() => {
              handles.close();
              if (tableRef.current) {
                tableRef.current.refresh();
              }
            });
          }
        },
      });
    };

    const handleTransferToRetsys = () => {
      dialogContainer.confirm.create({
        title: "Overføre poster til RetSys",
        message: "Dette kan tage nogle minutter.",
        onConfirm: async () => {
          dialogContainer.loading.start({
            title: "Overføre poster",
            message: "Vent venligst...",
          });
          try {
            await backend.get("/sqlrl/custom/sp_autorun", { timeout: 300000 });
            await backend.get("/member.retsys.export", {
              timeout: 1000 * 60 * 6,
            });
          } catch (error) {
            dialogContainer.error.handleError(error);
          } finally {
            dialogContainer.loading.stop();
          }
        },
      });
    };

    const handleJobFunctionModalOpen = () => {
      if (tableRef.current) {
        const filter = getFilterFromObject(tableRef.current.getFilterValues(), {
          wildcard: true,
        });
        if (filter.length > 0) {
          setJobFunctionModalOpen(true);
        } else {
          dialogContainer.confirm.create({
            title: "Ingen filter sat",
            message: "Tilføj venlist et eller flere filtere og prøv igen.",
            closeText: "Luk",
            confirmText: "OK",
          });
        }
      }
    };
    const handleJobFunctionModalClose = () => {
      setJobFunctionModalOpen(false);
    };
    const handleJobFunctionModalSelect = async (item: {
      name: string | null;
    }) => {
      if (item.name && tableRef.current) {
        const filter = getFilterFromObject(tableRef.current.getFilterValues(), {
          wildcard: true,
        });
        if (filter.length > 0) {
          try {
            handleJobFunctionModalClose();
            dialogContainer.loading.start({
              title: "Omdøber poster",
              message: "Vent venligst...",
            });
            await MemberProgramViewSchema.update(
              { jobFunction: item.name },
              { filter: filter as any }
            );
            tableRef.current.refresh();
          } catch (error) {
            dialogContainer.error.handleError(error);
          } finally {
            dialogContainer.loading.stop();
          }
        } else {
          dialogContainer.confirm.create({
            title: "Ingen filter sat",
            message: "Tilføj venlist et eller flere filtere og prøv igen.",
            closeText: "Luk",
            confirmText: "OK",
          });
        }
      }
    };

    const handleCreateComments = () => {
      dialogContainer.textField.create({
        title: "Opret kommentar",
        message: "Opret kommentar for filtrerede produktioner.",
        closeText: "Annuller",
        submitText: "Opret",
        initialValue: "",
        onSubmit: (value) => {
          if (tableRef.current && value) {
            const {
              params: { filter },
            } = getAxioscConfigFromSqlrlSelect({
              collection: "",
              filter: getFilterFromObject(tableRef.current.getFilterValues(), {
                wildcard: true,
              }),
            });

            backend
              .post(
                "member.production.approval.comment",
                { comment: value },
                {
                  params: {
                    filter,
                  },
                }
              )
              .then(() => tableRef.current && tableRef.current.refresh())
              .catch(dialogContainer.error.handleError);
          }
        },
      });
    };

    const handleApplicationPoolOpenMultiple = () => {
      if (tableRef.current) {
        const filter = getFilterFromObject(tableRef.current.getFilterValues(), {
          wildcard: true,
        });
        if (filter.length > 0) {
          setApplicationPoolDialogOpen({
            open: true,
            filter: filter,
          });
        } else {
          dialogContainer.confirm.create({
            title: "Ingen filter sat",
            message: "Tilføj venlist et eller flere filtere og prøv igen.",
            closeText: "Luk",
            confirmText: "OK",
          });
        }
      }
    };

    const handleApplicationPoolOpen = () => {
      if (link && link.filter && link.filter[0].value) {
        setApplicationPoolDialogOpen({
          open: true,
          filter: link.filter,
        });
      }
    };

    const handleApplicationPoolClose = () => {
      setApplicationPoolDialogOpen({ open: false, filter: null });
    };

    return (
      <React.Fragment>
        <TableContainer>
          <SqlrlWindowDataTable
            innerRef={tableRef}
            schema={MemberProgramViewSchema}
            title="Medlem/Program - Godkendelse"
            fields={[
              "memberId",
              "memberName",
              "status",
              "recruitmentForm",
              "jobFunction",
              "programChannel",
              "programTitle",
              "programSubtitle",
              "programSeasonNumber",
              "programEpisodeNumber",
              "displayYear",
              "approvedDate",
              "programMemberId",
              "manual",
              "commentCount",
              "workdays",
              "contractTitle",
              "notMe",
              "genre",
              "programId",
            ]}
            widths={{
              memberName: 160,
              programTitle: 320,
              jobFunction: 170,
              genre: 160,
            }}
            onRowClick={handleRowClick}
            contextMenuItems={[
              { label: "Vis alle", onClick: handleShowAll },
              { label: "Vis indsendte", onClick: handleShowSent },
              { label: "Overføre til RetSys", onClick: handleTransferToRetsys },
              { label: "Godkend/afvis", onClick: handleApproveTable },
              {
                label: "Omdøb job funktion",
                onClick: handleJobFunctionModalOpen,
              },

              {
                label: "Opret kommentar",
                onClick: handleCreateComments,
              },

              {
                label: "Ansøgningspuljer",
                onClick: handleApplicationPoolOpenMultiple,
              },
            ]}
          />
        </TableContainer>
        <SqlrlDetailsModal
          schema={MemberProgramViewSchema}
          fields={[
            "rowId",
            "memberId",
            "programTitle",
            "programMemberId",
            "programId",
            "memberContractId",
          ]}
          link={link}
          caption="Medlem/Program - Godkendelse"
          title="programTitle"
          editable={true}
          contextMenuItems={[
            { label: "Godkend/afvis", onClick: handleApproveDetail },
            { label: "Ansøgningspuljer", onClick: handleApplicationPoolOpen },
          ]}
          editRenderer={({ data, close, refresh }) => (
            <SqlrlAutoFormik
              schema={MemberProgramSchema}
              fields={[
                "status",
                "notMe",
                "copydanReduction",
                "workdaysReduction",
                "workdays",
                "jobFunction",
                "recruitmentForm",
                "skipCalculation",
              ]}
              returnFields={[]}
              filter={
                data
                  ? [
                      {
                        fieldName: "programMemberId",
                        value: data.programMemberId,
                      },
                    ]
                  : null
              }
              fieldRendererMap={{
                status: ({ field, form, sqlrlField }) => (
                  <FormikSelect
                    field={field}
                    meta={getFieldMeta(form, field.name)}
                    label={sqlrlField.label}
                    options={statusOptions}
                  />
                ),
                jobFunction: ({ field, form, sqlrlField }) => (
                  <FormikLookup
                    schema={PositionSchema}
                    fields={["name"]}
                    filter={[]}
                    valueField="name"
                    labelField="name"
                    field={field}
                    meta={getFieldMeta(form, field.name)}
                    label={sqlrlField.label}
                  />
                ),
                recruitmentForm: ({ field, form, sqlrlField }) => (
                  <FormikSelect
                    field={field}
                    meta={getFieldMeta(form, field.name)}
                    label={sqlrlField.label}
                    options={recruitmentFormOptions}
                  />
                ),
              }}
              initialValues={{}}
              onSuccess={() => {
                handles.close();
                if (tableRef.current) {
                  tableRef.current.refresh();
                }
              }}
              deletable={authContainer.role === "ADMIN"}
              onDelete={close}
            />
          )}
          tabs={[
            {
              label: "Kontrakter",
              renderer: ({ data }) => (
                <SqlrlWindowDataTable
                  schema={MemberContractLinkViewSchema}
                  fields={[
                    "memberContractTitle",
                    "filename",
                    "comment",
                    "memberContractLinkId",
                  ]}
                  filter={[
                    {
                      fieldName: "memberContractId",
                      value:
                        data.memberContractId ||
                        "{00000000-0000-0000-0000-000000000000}",
                    },
                  ]}
                  menuBarItems={[
                    { label: "Godkend", onClick: handleApproveWithDefault },
                  ]}
                  title="Kontrakter"
                  onRowClick={handleContractClick}
                />
              ),
            },
            {
              label: "Kommentar",
              renderer: ({ data }) => (
                <SqlrlWindowDataTable
                  innerRef={commentTableRef}
                  schema={MemberProgramCommentViewSchema}
                  fields={["timestamp", "comment", "userEmail"]}
                  filter={[
                    {
                      fieldName: "programMemberId",
                      value: data.programMemberId,
                    },
                  ]}
                  title="Kommentar"
                  menuBarItems={[
                    {
                      label: "Opret kommentar",
                      onClick: () => handleCommentCreate(data.programMemberId),
                    },
                  ]}
                  onRowClick={handleCommentClick}
                />
              ),
            },
            {
              label: "Udsendelser",
              renderer: ({ data }) => (
                <SqlrlWindowDataTable
                  schema={BroadcastSchema}
                  fields={["startTime", "duration", "channel"]}
                  filter={[
                    {
                      fieldName: "programId",
                      value: data.programId,
                    },
                  ]}
                  title="Udsendelse"
                />
              ),
            },
            {
              label: "General",
              renderer: ({ data }) => (
                <Box p={3}>
                  <SqlrlDetailView
                    schema={MemberProgramViewSchema}
                    fields={[
                      "programId",
                      "created",
                      "userEmail",
                      "memberId",
                      "displayYear",
                      "status",
                      "notMe",
                      "jobFunction",
                      "recruitmentForm",
                      "programChannel",
                      "programTitle",
                      "programSubtitle",
                      "programSeasonNumber",
                      "programEpisodeNumber",
                      "programProductionNumber",
                      "programProductionYear",
                      "programProductionCountry",
                      "contractTitle",
                      "memberName",
                      "workdays",
                      "applicationFlowTvFunds",
                      "applicationFlowTvSff",
                      "applicationAvuFunds",
                      "applicationDrArchiveFlow",
                      "applicationDrArchiveClip",
                      "applicationDrAcrhiveDrDk",
                      "applicationDrArchiveDrDkClip",
                      "applicationBonanza",
                    ]}
                    filter={[
                      {
                        fieldName: "programMemberId",
                        value: data.programMemberId,
                      },
                    ]}
                    title="General"
                  />
                </Box>
              ),
            },
            {
              label: "Medlemmer",
              renderer: ({ data }) => (
                <Box p={3}>
                  <SqlrlDetailView
                    schema={MemberSchema}
                    fields={[
                      "memberId",
                      "name",
                      "address",
                      "postCode",
                      "city",
                      "phoneNumber",
                      "mobile",
                      "email",
                      "alias1",
                      "alias2",
                      "alias3",
                      "alias4",
                      "alias5",
                      "alias6",
                      "alias7",
                      "alias8",
                      "alias9",
                      "alias10",
                    ]}
                    filter={[
                      {
                        fieldName: "memberId",
                        value: data.memberId,
                      },
                    ]}
                    title="Medlemmer"
                  />
                </Box>
              ),
            },
            {
              label: "Personer",
              renderer: ({ data }) => (
                <SqlrlWindowDataTable
                  schema={PersonSchema}
                  fields={["name", "role", "rowId"]}
                  filter={[
                    {
                      fieldName: "programId",
                      value: data.programId,
                    },
                  ]}
                  title="Personer"
                />
              ),
            },
          ]}
        />
        <JobFunctionModal
          open={jobFunctionModalOpen}
          onClose={handleJobFunctionModalClose}
          onSelect={handleJobFunctionModalSelect}
        />
        <ApplicationPoolDialog
          filter={applicationPoolDialogOpen.filter}
          open={applicationPoolDialogOpen.open}
          onClose={handleApplicationPoolClose}
        />
      </React.Fragment>
    );
  };
