import React from "react";
import { RouteComponentProps } from "@reach/router";

// Components
import { TableContainer } from "@discoverr/ui/components/Table/TableContainer";
import { SqlrlWindowDataTable } from "@discoverr/ui/components/Table/SqlrlWindowDataTable";

// Schema
import { BroadcastApprovalViewSchema } from "schema";
import { ISqlrlSchemaPayload } from "@discoverr/ui/utilities/sqlrl";

//
export interface IListProps extends RouteComponentProps {}
export const List: React.FunctionComponent<IListProps> = props => {
  const handleRowClick = (
    broadcast: ISqlrlSchemaPayload<typeof BroadcastApprovalViewSchema>
  ) => {
    props.navigate!(`./${broadcast.broadcastId}/frames`);
  };
  return (
    <React.Fragment>
      <TableContainer>
        <SqlrlWindowDataTable
          schema={BroadcastApprovalViewSchema}
          title="Godkendelse"
          fields={[
            "startTime",
            "status",
            "channel",
            "title",
            "productionCountry",
            "productionYear",
            "duration",
            "seasonNumber",
            "episodeNumber",
            "productionNumber",
            "broadcastId"
          ]}
          widths={{
            startTime: 170,
            title: 500,
            productionCountry: 180,
            productionNumber: 200
          }}
          onRowClick={handleRowClick}
        />
      </TableContainer>
    </React.Fragment>
  );
};
