import React from "react";
import { RouteComponentProps } from "@reach/router";

// Backend
import { backend } from "backend";

// Container
import { DialogContainer } from "@discoverr/ui/containers/DialogContainer";
import { AuthContainer } from "containers/AuthContainer";

// Components
import Box from "@material-ui/core/Box";

import { TableContainer } from "@discoverr/ui/components/Table/TableContainer";
import {
  SqlrlWindowDataTable,
  ISqlrlWindowDataTableHandles,
} from "@discoverr/ui/components/Table/SqlrlWindowDataTable";
import {
  SqlrlDetailsModal,
  useSqlrlDetailsModalLink,
} from "@discoverr/ui/components/Modal/SqlrlDetailsModal";

import {
  SqlrlFormikModal,
  useSqlrlFormikModalLink,
} from "@discoverr/ui/components/Modal/SqlrlFormikModal";
import { getFieldMeta, FormikLookup } from "@discoverr/ui/components/Formik";

import { ProgramPersonTab } from "components/ProgramPersonTab";
import { MemberTab } from "./components/MemberTab";
import { ProgramGenerelTab } from "components/ProgramGenerelTab";
import { BroadcastListTab } from "components/BroadcastListTab";

import { getFilterFromObject } from "@discoverr/ui/utilities/sqlrl";

// Schema
import {
  BroadcastSchema,
  ProgramSchema,
  PersonSchema,
  IBroadcastSchemaPayload,
  PositionSchema,
  ProgramChannelViewSchema,
} from "schema";
import { ISqlrlSchemaPayload } from "@discoverr/ui/utilities/sqlrl";
import { SqlrlAutoFormik } from "@discoverr/ui/components/Formik/SqlrlAutoFormik";

import { ProgramImageTab } from "components/ProgramImageTab";
import { BroadcastReplayListTab } from "components/BroadcastReplayListTab";
import { BroadcastAvuListTab } from "components/BroadcastAvuListTab";
import { BroadcastBonanzaListTab } from "components/BroadcastBonanzaListTab";
import { BroadcastCopydanListTab } from "components/BroadcastCopydanListTab";

//
export interface IProgramsProps extends RouteComponentProps {}
export const Programs: React.FunctionComponent<IProgramsProps> = (props) => {
  const authContainer = AuthContainer.useContainer();

  const isAdmin = authContainer.role === "ADMIN";
  const isUser = authContainer.role === "USER";
  const isSuperUser = authContainer.role === "SUPERUSER";

  const dialogContainer = DialogContainer.useContainer();

  const tableRef =
    React.useRef<ISqlrlWindowDataTableHandles<typeof ProgramSchema>>();

  const tableBroadcastRef =
    React.useRef<ISqlrlWindowDataTableHandles<typeof BroadcastSchema>>();

  const tablePersonRef =
    React.useRef<ISqlrlWindowDataTableHandles<typeof PersonSchema>>();

  const [link, handles] = useSqlrlDetailsModalLink<typeof ProgramSchema>();

  const [personLink, personHandles] =
    useSqlrlFormikModalLink<typeof PersonSchema>();

  const handleProgramCreate = () => {
    handles.create();
  };

  const handleRowClick = (
    broadcast: ISqlrlSchemaPayload<typeof ProgramSchema>
  ) => {
    handles.view([{ fieldName: "programId", value: broadcast.programId }]);
  };

  const handleSuccess = () => {
    if (tableRef.current) {
      tableRef.current.refresh();
    }
  };

  const handleMoveToApproval = async () => {
    if (
      link.filter &&
      link.filter[0] &&
      link.filter[0].fieldName === "programId"
    ) {
      try {
        await backend.get("/sqlrl/custom/sp_program_delete", {
          params: {
            programId: link.filter[0].value,
          },
        });
        handles.close();
        if (tableRef.current) {
          tableRef.current.refresh();
        }
      } catch (error) {
        dialogContainer.error.handleError(error);
      }
    }
  };

  const handleBroadcastToApproval = async (
    row: Pick<
      IBroadcastSchemaPayload,
      "startTime" | "duration" | "channel" | "broadcastId"
    >
  ) => {
    dialogContainer.confirm.create({
      title: "Flyt udsendelse til godkendelse",
      message:
        "Ved at klikke på confirm bekræfter du at flytte udsendelsen til godekendelsessiden.",
      onConfirm: () => {
        BroadcastSchema.update(
          {
            status: "KLAR",
            approved: false,
            programId: null,
          },
          { filter: [{ fieldName: "broadcastId", value: row.broadcastId }] }
        )
          .then(() => {
            tableBroadcastRef.current && tableBroadcastRef.current.refresh();
          })
          .catch(dialogContainer.error.handleError);
      },
    });
  };

  const handleRenameGenre = () => {
    if (tableRef.current) {
      var count = tableRef.current.getListCount();
      dialogContainer.textField.create({
        title: "Omdøb Genre",
        message: `Alle program restultater fra indtastede filter vil få omdøbt genre.`,
        onSubmit: (value) => {
          if (value && tableRef.current) {
            ProgramSchema.update(
              { genre: value },
              {
                filter: getFilterFromObject<any>(
                  tableRef.current.getFilterValues(),
                  { wildcard: true }
                ),
              }
            )
              .then(tableRef.current.refresh)
              .catch(dialogContainer.error.handleError);
          }
        },
      });
    }
  };

  return (
    <React.Fragment>
      <TableContainer>
        <SqlrlWindowDataTable
          schema={ProgramSchema}
          innerRef={tableRef}
          title="Programmer"
          fields={[
            "title",
            "subtitle",
            "channel",
            "genre",
            "seasonNumber",
            "episodeNumber",
            "productionYear",
            "productionNumber",
            "missingCredit",
            "programId",
          ]}
          widths={{ title: 280 }}
          contextMenuItems={[
            {
              label: "Opret Program",
              onClick: handleProgramCreate,
            },
            {
              label: "Omdøb Genre",
              onClick: handleRenameGenre,
            },
          ]}
          onRowClick={handleRowClick}
        />
      </TableContainer>
      <SqlrlDetailsModal
        schema={ProgramSchema}
        fields={[
          "programId",
          "title",
          "channel",
          "genre",
          "episodeNumber",
          "seasonNumber",
          "productionYear",
        ]}
        link={link}
        caption="Program"
        title="title"
        contextMenuItems={
          isAdmin
            ? [
                {
                  label: "Flyt til godkendelse",
                  onClick: handleMoveToApproval,
                },
              ]
            : undefined
        }
        editable={isAdmin}
        editRenderer={
          isAdmin
            ? ({ data, close, refresh }) => (
                <SqlrlAutoFormik
                  schema={ProgramSchema}
                  fields={[
                    "title",
                    "subtitle",
                    "channel",
                    "duration",
                    "genre",
                    "seasonNumber",
                    "episodeNumber",
                    "productionYear",
                    "productionNumber",
                    "originalTitle",
                    "seriesTitle",
                    "seasonTitle",
                    "description",
                    "programReduction",
                  ]}
                  returnFields={[]}
                  fieldRendererMap={{
                    channel: ({ field, form, sqlrlField }) => (
                      <FormikLookup
                        schema={ProgramChannelViewSchema}
                        fields={["channel"]}
                        filter={[]}
                        valueField="channel"
                        labelField="channel"
                        field={field}
                        meta={getFieldMeta(form, field.name)}
                        label={sqlrlField.label}
                      />
                    ),
                  }}
                  filter={
                    data
                      ? [{ fieldName: "programId", value: data.programId }]
                      : null
                  }
                  initialValues={{}}
                  onSuccess={() => {
                    handleSuccess();
                    refresh && refresh(true);
                  }}
                  onCreate={close}
                  onDelete={close}
                  deletable
                />
              )
            : undefined
        }
        tabs={[
          {
            label: "General",
            renderer: ({ data }) => (
              <ProgramGenerelTab programId={data.programId} />
            ),
          },
          {
            label: "Personer",
            renderer: ({ data }) => <ProgramPersonTab data={data} />,
          },
          {
            label: "Medlem",
            renderer: ({ data }) =>
              (isAdmin || isUser || isSuperUser) && <MemberTab data={data} />,
          },
          {
            label: "Udsendelser",
            renderer: ({ data }) => (
              <BroadcastListTab programId={data.programId} />
            ),
          },
          {
            label: "AVU",
            renderer: ({ data }) =>
              (isAdmin || isUser || isSuperUser) &&
              data.programId && (
                <BroadcastAvuListTab programId={data.programId} />
              ),
          },
          {
            label: "Bonanza",
            renderer: ({ data }) =>
              (isAdmin || isUser || isSuperUser) &&
              data.programId && (
                <BroadcastBonanzaListTab programId={data.programId} />
              ),
          },
          {
            label: "Copydan",
            renderer: ({ data }) =>
              (isAdmin || isUser || isSuperUser) &&
              data.programId && (
                <BroadcastCopydanListTab programId={data.programId} />
              ),
          },
          {
            label: "Start Forfra",
            renderer: ({ data }) =>
              (isAdmin || isUser || isSuperUser) &&
              data.programId && (
                <BroadcastReplayListTab programId={data.programId} />
              ),
          },
          {
            label: "Billeder",
            renderer: ({ data }) => <ProgramImageTab data={data} />,
          },
        ]}
      />
    </React.Fragment>
  );
};
