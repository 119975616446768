import React from "react";
import { RouteComponentProps } from "@reach/router";

// Components
import Paper from "@material-ui/core/Paper";

//
export interface IStatusProps extends RouteComponentProps {}
export const Status: React.FunctionComponent<IStatusProps> = props => {
  return (
    <Paper style={{ width: "100%", maxWidth: 960, margin: "16px auto" }}>
      <iframe
        src="http://recording.eagleeyes.cloud/auto.recording.status"
        style={{
          display: "flex",
          overflow: "hidden",
          width: "100%",
          height: "80vh",
          border: "none"
        }}
      ></iframe>
    </Paper>
  );
};
