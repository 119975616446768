import React from "react";
import { RouteComponentProps } from "@reach/router";

// Utilities
import {
  ISqlrlSchemaPayload,
  getFilterFromObject,
} from "@discoverr/ui/utilities/sqlrl";

// Containers
import { DialogContainer } from "@discoverr/ui/containers/DialogContainer";

// Components
import Box from "@material-ui/core/Box";
import {
  ISqlrlWindowDataTableHandles,
  SqlrlWindowDataTable,
} from "@discoverr/ui/components/Table/SqlrlWindowDataTable";
import {
  SqlrlDetailsModal,
  useSqlrlDetailsModalLink,
} from "@discoverr/ui/components/Modal/SqlrlDetailsModal";
import { SqlrlDetailView } from "@discoverr/ui/components/Detail/SqlrlDetailView";
import { SqlrlAutoFormik } from "@discoverr/ui/components/Formik/SqlrlAutoFormik";
import { TableContainer } from "@discoverr/ui/components/Table/TableContainer";
import { FormikLookup } from "@discoverr/ui/components/Formik/FormikLookup";
import { FileUploadDialog } from "components/FileUploadDialog";

// Schemas
import {
  // ProgramBonanzaViewSchema,
  // ProgramBonanzaSchema,
  ProgramSchema,
  BroadcastImportSchema,
  ProgramChannelViewSchema,
} from "schema";
import {
  getFieldMeta,
  FormikSelect,
  IFormikSelectOption,
} from "@discoverr/ui/components/Formik";
import { backend } from "backend";
import { ProgramGenerelTab } from "components/ProgramGenerelTab";
import { BroadcastListTab } from "components/BroadcastListTab";

// Constants
const statusOptions: IFormikSelectOption[] = [
  { value: "INDLÆST", label: "INDLÆST" },
  { value: "AFVENT", label: "AFVENT" },
  { value: "OVERFØRT", label: "OVERFØRT" },
];

//
export interface IBroadcastImportProps extends RouteComponentProps {}
export const BroadcastImport: React.FunctionComponent<IBroadcastImportProps> = (
  props
) => {
  const dialogContainer = DialogContainer.useContainer();

  const tableRef =
    React.useRef<ISqlrlWindowDataTableHandles<typeof BroadcastImportSchema>>();

  const [link, handles] =
    useSqlrlDetailsModalLink<typeof BroadcastImportSchema>();

  const [showUpload, setShowUploader] = React.useState(false);

  const handleRowClick = (
    row: ISqlrlSchemaPayload<typeof BroadcastImportSchema>
  ) => {
    handles.view([{ fieldName: "broadcastId", value: row.broadcastId }]);
  };

  const hancleCreate = () => {
    handles.create();
  };

  const handleUploadDocument = () => {
    setShowUploader(true);
  };

  const handleCloseUploadDocument = () => {
    setShowUploader(false);
    tableRef.current && tableRef.current.refresh();
  };

  const handleDeleteAllLines = () => {
    dialogContainer.confirm.create({
      title: "Slet alle linjer",
      message: "Bekræft sletning af linjer.",
      confirmText: "Bekræft",
      closeText: "Annuller",
      onConfirm: () => {
        if (tableRef.current) {
          BroadcastImportSchema.delete({
            filter: getFilterFromObject(tableRef.current.getFilterValues(), {
              wildcard: true,
            }) as any,
          })
            .then(() => {
              if (tableRef.current) {
                tableRef.current.refresh();
              }
            })
            .catch((error) => {
              dialogContainer.error.handleError(error);
            });
        }
      },
    });
  };

  const handleSuccess = () => {
    if (tableRef.current) {
      tableRef.current.refresh();
    }
  };

  const handleTransferApproved = () => {
    dialogContainer.loading.start({
      title: "Vent venligst",
      message: "Mens data overføres...",
    });
    backend
      .get("/sqlrl/custom/sp_broadcast_import", {
        timeout: 60 * 10 * 1000,
      })
      .then(() => {
        if (tableRef.current) {
          tableRef.current.refresh();
        }
      })
      .catch(dialogContainer.error.handleError)
      .finally(() => dialogContainer.loading.stop());
  };

  return (
    <React.Fragment>
      <TableContainer>
        <SqlrlWindowDataTable
          schema={BroadcastImportSchema}
          innerRef={tableRef}
          title="Importer udsendelser"
          fields={[
            "status",
            "startTime",
            "endTime",
            "channel",
            "title",
            "subtitle",
            "seasonNumber",
            "episodeNumber",
            "productionYear",
            "duration",
            "productionCountry",
            "productionNumber",
            "status",
            "genre",
            "programId",
            "broadcastId",
          ]}
          widths={{ title: 400, subtitle: 400 }}
          contextMenuItems={[
            {
              label: "Opret ny",
              onClick: hancleCreate,
            },
            {
              label: "Upload fil",
              onClick: handleUploadDocument,
            },
            {
              label: "Slet alle linjer",
              onClick: handleDeleteAllLines,
            },
            {
              label: "Overfør",
              onClick: handleTransferApproved,
            },
          ]}
          onRowClick={handleRowClick}
        />
      </TableContainer>
      <SqlrlDetailsModal
        schema={BroadcastImportSchema}
        fields={["broadcastId", "title", "programId"]}
        link={link}
        caption="Bonanza"
        title={"title"}
        editable={true}
        editRenderer={({ data, close, refresh }) => (
          <SqlrlAutoFormik
            schema={BroadcastImportSchema}
            fields={[
              "programId",
              "status",
              "channel",
              "startTime",
              "endTime",
              "title",
              "subtitle",
              "duration",
              "seasonNumber",
              "episodeNumber",
              "productionNumber",
              "productionYear",
              "productionCountry",
              "genre",
              "isReturn",
              "originalTitle",
              "seriesTitle",
              "seasonTitle",
              "announcedStartTime",
              "announcedEndTime",
              "description",
            ]}
            returnFields={[]}
            fieldRendererMap={{
              programId: ({ field, form, sqlrlField }) => (
                <FormikLookup
                  schema={ProgramSchema}
                  fields={[
                    "title",
                    "subtitle",
                    "channel",
                    "genre",
                    "seasonNumber",
                    "episodeNumber",
                    "productionYear",
                    "productionNumber",
                    "programId",
                  ]}
                  filter={[]}
                  valueField="programId"
                  labelField="title"
                  field={field}
                  meta={getFieldMeta(form, field.name)}
                  label={sqlrlField.label}
                />
              ),
              status: ({ field, form, sqlrlField }) => (
                <FormikSelect
                  field={field}
                  meta={getFieldMeta(form, field.name)}
                  label={sqlrlField.label}
                  options={statusOptions}
                />
              ),
              channel: ({ field, form, sqlrlField }) => (
                <FormikLookup
                  schema={ProgramChannelViewSchema}
                  fields={["channel"]}
                  filter={[]}
                  valueField="channel"
                  labelField="channel"
                  field={field}
                  meta={getFieldMeta(form, field.name)}
                  label={sqlrlField.label}
                />
              ),
            }}
            filter={
              data
                ? [{ fieldName: "broadcastId", value: data.broadcastId }]
                : null
            }
            initialValues={{}}
            onSuccess={() => {
              handleSuccess();
              refresh && refresh();
            }}
            onDelete={close}
            deletable
          />
        )}
        tabs={[
          {
            label: "General",
            renderer: ({ data }) => (
              <Box p={3}>
                <SqlrlDetailView
                  schema={BroadcastImportSchema}
                  fields={[
                    "startTime",
                    "endTime",
                    "programId",
                    "channel",
                    "title",
                    "subtitle",
                    "duration",
                    "seasonNumber",
                    "episodeNumber",
                    "productionNumber",
                    "productionYear",
                    "productionCountry",
                    "genre",
                    "isReturn",
                    "originalTitle",
                    "seriesTitle",
                    "seasonTitle",
                    "announcedStartTime",
                    "announcedEndTime",
                    "status",
                    "description",
                  ]}
                  filter={[
                    {
                      fieldName: "broadcastId",
                      value: data.broadcastId,
                    },
                  ]}
                  title="Start Forfra"
                />
              </Box>
            ),
          },
          {
            label: "Program",
            renderer: ({ data }) =>
              data.programId && (
                <ProgramGenerelTab programId={data.programId} />
              ),
          },
          {
            label: "Udsendelser",
            renderer: ({ data }) =>
              data.programId && <BroadcastListTab programId={data.programId} />,
          },
        ]}
      />
      <FileUploadDialog
        path="import.broadcast"
        open={showUpload}
        onClose={handleCloseUploadDocument}
      ></FileUploadDialog>
    </React.Fragment>
  );
};
