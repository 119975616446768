import React from "react";

// Components
import Box from "@material-ui/core/Box";

//
export interface IHeaderFlexLayoutProps {
  header: React.ReactNode;
}
export const HeaderFlexLayout: React.FunctionComponent<
  IHeaderFlexLayoutProps
> = props => {
  return (
    <Box height="100%" display="flex" flexDirection="column">
      {props.header}
      <Box display="flex" flexDirection="column" flexGrow={1}>
        {props.children}
      </Box>
    </Box>
  );
};
