import React from "react";
import { RouteComponentProps } from "@reach/router";

// Utilities
import { ISqlrlSchemaPayload } from "@discoverr/ui/utilities/sqlrl";

// Containers
import { DialogContainer } from "@discoverr/ui/containers/DialogContainer";

// Components
import {
  ISqlrlWindowDataTableHandles,
  SqlrlWindowDataTable,
} from "@discoverr/ui/components/Table/SqlrlWindowDataTable";
import { TableContainer } from "@discoverr/ui/components/Table/TableContainer";

// Schemas
import { PositionSchema } from "schema";
import {
  SqlrlDetailsModal,
  useSqlrlDetailsModalLink,
} from "@discoverr/ui/components/Modal/SqlrlDetailsModal";
import { SqlrlAutoFormik } from "@discoverr/ui/components/Formik/SqlrlAutoFormik";
import Box from "@material-ui/core/Box";
import { SqlrlDetailView } from "@discoverr/ui/components/Detail/SqlrlDetailView";

//
export interface IPositionsProps extends RouteComponentProps {}
export const Positions: React.FunctionComponent<IPositionsProps> = (props) => {
  const dialogContainer = DialogContainer.useContainer();

  const tableRef = React.useRef<
    ISqlrlWindowDataTableHandles<typeof PositionSchema>
  >();

  const [link, handles] = useSqlrlDetailsModalLink<typeof PositionSchema>();

  const handleRowClick = (
    position: ISqlrlSchemaPayload<typeof PositionSchema>
  ) => {
    handles.view([{ fieldName: "rowId", value: position.rowId }]);
  };

  const hancleCreate = () => {
    dialogContainer.textField.create({
      title: "Tilføj position",
      message: "Tilføj en position to listen.",
      label: "Position",
      onSubmit: async (value) => {
        if (value) {
          try {
            await PositionSchema.insert({ name: value });
            if (tableRef.current) {
              tableRef.current.refresh();
            }
          } catch (error) {
            dialogContainer.error.handleError(error);
          }
        } else {
          dialogContainer.alert.create({
            title: "Tilføjelse fejlede",
            message: "Udfyld venligst 'Ord'.",
            status: "error",
            onClose: () => {},
            onExited: () => {},
          });
        }
      },
    });
  };
  return (
    <React.Fragment>
      <TableContainer>
        <SqlrlWindowDataTable
          schema={PositionSchema}
          innerRef={tableRef}
          title="Positioner"
          fields={["name", "created", "showOnMemberPortal", "rowId"]}
          contextMenuItems={[
            {
              label: "Tilføj position",
              onClick: hancleCreate,
            },
          ]}
          onRowClick={handleRowClick}
        />
      </TableContainer>
      <SqlrlDetailsModal
        schema={PositionSchema}
        fields={["rowId", "name"]}
        link={link}
        caption="Position"
        title={"name"}
        editable={true}
        editRenderer={({ data, close, refresh }) => (
          <SqlrlAutoFormik
            schema={PositionSchema}
            fields={["name", "showOnMemberPortal"]}
            returnFields={[]}
            filter={data ? [{ fieldName: "rowId", value: data.rowId }] : null}
            initialValues={{}}
            onSuccess={() => {
              refresh && refresh(true);
              tableRef.current && tableRef.current.refresh();
            }}
            onDelete={close}
            deletable
          />
        )}
        tabs={[
          {
            label: "General",
            renderer: ({ data }) => (
              <Box p={3}>
                <SqlrlDetailView
                  schema={PositionSchema}
                  fields={["name", "showOnMemberPortal"]}
                  filter={[
                    {
                      fieldName: "rowId",
                      value: data.rowId,
                    },
                  ]}
                  title="Bruger"
                />
              </Box>
            ),
          },
        ]}
      />
    </React.Fragment>
  );
};
