import React from "react";
import { RouteComponentProps } from "@reach/router";

// Components
import Box from "@material-ui/core/Box";

import { TableContainer } from "@discoverr/ui/components/Table/TableContainer";
import { SqlrlWindowDataTable } from "@discoverr/ui/components/Table/SqlrlWindowDataTable";
import {
  SqlrlDetailsModal,
  useSqlrlDetailsModalLink,
} from "@discoverr/ui/components/Modal/SqlrlDetailsModal";
import { SqlrlDetailView } from "@discoverr/ui/components/Detail/SqlrlDetailView";

// Schema
import {
  PersonSchema,
  PersonViewSchema,
  PersonProgramViewSchema,
  PersonBroadcastViewSchema,
} from "schema";
import { ISqlrlSchemaPayload } from "@discoverr/ui/utilities/sqlrl";

//
export interface IPeopleProps extends RouteComponentProps {}
export const People: React.FunctionComponent<IPeopleProps> = (props) => {
  const [link, handles] = useSqlrlDetailsModalLink<typeof PersonSchema>();

  const handleRowClick = (
    person: ISqlrlSchemaPayload<typeof PersonViewSchema>
  ) => {
    handles.view([{ fieldName: "rowId", value: person.rowId }]);
  };
  return (
    <React.Fragment>
      <TableContainer>
        <SqlrlWindowDataTable
          schema={PersonViewSchema}
          title="Personer"
          fields={["name", "role", "rowId"]}
          onRowClick={handleRowClick}
        />
      </TableContainer>
      <SqlrlDetailsModal
        schema={PersonSchema}
        fields={["rowId", "programId", "name", "role"]}
        link={link}
        caption="Personer"
        title="name"
        editable={false}
        tabs={[
          {
            label: "General",
            renderer: ({ data }) => (
              <Box p={3}>
                <SqlrlDetailView
                  schema={PersonSchema}
                  fields={["name", "role"]}
                  filter={[
                    {
                      fieldName: "rowId",
                      value: data.rowId,
                    },
                  ]}
                  title="Person"
                />
              </Box>
            ),
          },
          {
            label: "Programmer",
            renderer: ({ data }) => (
              <SqlrlWindowDataTable
                schema={PersonProgramViewSchema}
                fields={[
                  "name",
                  "programTitle",
                  "programSubtitle",
                  "role",
                  "programGenre",
                  "programProductionYear",
                  "programProductionNumber",
                  "programSeasonNumber",
                  "programEpisodeNumber",
                  "programDuration",
                ]}
                filter={[
                  { fieldName: "name", value: data.name },
                  { fieldName: "role", value: data.role },
                ]}
                title="Programmer"
                initialFilterVisibility={false}
                widths={{
                  name: 300,
                  programTitle: 300,
                  programSubtitle: 300,
                  programProductionNumber: 150,
                }}
              />
            ),
          },
          {
            label: "Udsendelser",
            renderer: ({ data }) => (
              <SqlrlWindowDataTable
                schema={PersonBroadcastViewSchema}
                fields={[
                  "startTime",
                  "productionNumber",
                  "title",
                  "seasonNumber",
                  "episodeNumber",
                  "productionYear",
                  "productionCountry",
                  "programId",
                ]}
                widths={{
                  productionNumber: 200,
                  title: 280,
                }}
                filter={[
                  { fieldName: "name", value: data.name },
                  { fieldName: "role", value: data.role },
                ]}
                title="Udsendelser"
                initialFilterVisibility={false}
              />
            ),
          },
        ]}
      />
    </React.Fragment>
  );
};
