import React from "react";

// Utilities
import { ISqlrlSchemaPayload } from "@discoverr/ui/utilities/sqlrl";

// Components
import {
  SqlrlWindowDataTable,
  ISqlrlWindowDataTableHandles,
} from "@discoverr/ui/components/Table/SqlrlWindowDataTable";
import { DataTableDialog } from "@discoverr/ui/components/Dialog";

import {
  SqlrlAutoFormikModal,
  useSqlrlAutoFormikModalLink,
} from "@discoverr/ui/components/Modal/SqlrlAutoFormikModal";
import {
  FormikLookup,
  getFieldMeta,
  FormikSelect,
  IFormikSelectOption,
} from "@discoverr/ui/components/Formik";

// Models
import {
  BroadcastViewSchema,
  IBroadcastViewSchemaPayload,
  ProgramSchema,
  IProgramSchemaPayload,
  ProgramChannelViewSchema,
} from "schema";
import { DialogContainer } from "@discoverr/ui/containers/DialogContainer";

// Constants
const statusOptions: IFormikSelectOption[] = [
  { value: "AUTO", label: "AUTO" },
  { value: "GODKENDT", label: "GODKENDT" },
  { value: "KLAR", label: "KLAR" },
  { value: "OCR", label: "OCR" },
  { value: "UDSKUDT", label: "UDSKUDT" },
];

//
export interface IBroadcastListTabProps {
  programId: ISqlrlSchemaPayload<typeof ProgramSchema>["programId"];
}
export const BroadcastListTab: React.FunctionComponent<IBroadcastListTabProps> = ({
  programId,
}) => {
  const dialogContainer = DialogContainer.useContainer();
  const tableRef = React.useRef<
    ISqlrlWindowDataTableHandles<typeof BroadcastViewSchema>
  >();

  const [link, handles] = useSqlrlAutoFormikModalLink<
    typeof BroadcastViewSchema
  >();

  const handleRowClick = async (
    row: Pick<
      IBroadcastViewSchemaPayload,
      "startTime" | "duration" | "channel" | "broadcastId"
    >
  ) => {
    dialogContainer.radioGroup.create({
      title: "Vælg handling",
      options: [
        { label: "Rediger udsendelse", value: "1" },
        { label: "Flyt til godkendelse", value: "2" },
      ],
      closeText: "Luk",
      submitText: "Vælg",
      onSubmit: (value) => {
        if (value.value === "1") {
          handles.update([
            { fieldName: "broadcastId", value: row.broadcastId },
          ]);
        }
        if (value.value === "2") {
          setTimeout(() => {
            moveToApproval(row);
          });
        }
      },
    });
  };

  const moveToApproval = (
    row: Pick<
      IBroadcastViewSchemaPayload,
      "startTime" | "duration" | "channel" | "broadcastId"
    >
  ) => {
    dialogContainer.confirm.create({
      title: "Flyt udsendelse til godkendelse",
      message:
        "Ved at klikke på confirm bekræfter du at flytte udsendelsen til godekendelsessiden.",
      confirmText: "Bekræft",
      closeText: "Annuller",
      onConfirm: () => {
        BroadcastViewSchema.update(
          {
            status: "KLAR",
            approved: false,
            programId: null,
          },
          { filter: [{ fieldName: "broadcastId", value: row.broadcastId }] }
        )
          .then(() => {
            tableRef.current && tableRef.current.refresh();
          })
          .catch(dialogContainer.error.handleError);
      },
    });
  };

  const handleSuccess = () => {
    if (tableRef.current) {
      tableRef.current.refresh();
    }
  };

  const handleBroadcastCreate = () => {
    handles.create();
  };
  const [moveLookupOpen, setMoveLookup] = React.useState(false);

  const handleMoveLookupClose = () => {
    setMoveLookup(false);
  };

  const handleMoveProgramOpen = () => {
    setMoveLookup(true);
  };
  const handleMoveProgramSelect = (
    row: Pick<IProgramSchemaPayload, "programId">
  ) => {
    dialogContainer.confirm.create({
      title: "Bekræft flytning",
      message: "Udsendelserne vil blive flyttet til det valgte program.",
      confirmText: "Bekræft",
      closeText: "Annuller",
      onConfirm: () => {
        BroadcastViewSchema.update(
          { programId: row.programId },
          { filter: [{ fieldName: "programId", value: programId }] }
        )
          .then(() => {
            handleMoveLookupClose();
            tableRef.current && tableRef.current.refresh();
          })
          .catch(dialogContainer.error.handleError);
      },
    });
  };

  return (
    <React.Fragment>
      <SqlrlWindowDataTable
        innerRef={tableRef}
        schema={BroadcastViewSchema}
        fields={[
          "startTime",
          "duration",
          "channel",
          "isReturn",
          "productionNumber",
          "title",
          "seasonNumber",
          "episodeNumber",
          "productionYear",
          "ssf",
          "broadcastId",
        ]}
        widths={{ title: 300 }}
        filter={[
          {
            fieldName: "programId",
            value: programId,
          },
        ]}
        menuBarItems={[
          {
            iconName: "add",
            label: "Opret udsendelse",
            onClick: handleBroadcastCreate,
          },
        ]}
        contextMenuItems={[
          {
            label: "Flyt alle udsendelse",
            onClick: handleMoveProgramOpen,
          },
        ]}
        title="Udsendelser"
        onRowClick={handleRowClick}
      />
      <SqlrlAutoFormikModal
        title="Message In"
        link={link}
        schema={BroadcastViewSchema}
        fields={[
          "programId",
          "status",
          "channel",
          "startTime",
          "endTime",
          "title",
          "subtitle",
          "duration",
          "seasonNumber",
          "episodeNumber",
          "productionNumber",
          "productionYear",
          "productionCountry",
          "genre",
          "isReturn",
          "originalTitle",
          "seriesTitle",
          "seasonTitle",
          "announcedStartTime",
          "announcedEndTime",
          "description",
          "approved",
        ]}
        returnFields={[]}
        fieldRendererMap={{
          programId: ({ field, form, sqlrlField }) => (
            <FormikLookup
              schema={ProgramSchema}
              fields={[
                "productionNumber",
                "title",
                "seasonNumber",
                "episodeNumber",
                "productionYear",
                "productionCountry",
                "programId",
              ]}
              filter={[]}
              valueField="programId"
              labelField="programId"
              field={field}
              meta={getFieldMeta(form, field.name)}
              label={sqlrlField.label}
            />
          ),
          status: ({ field, form, sqlrlField }) => (
            <FormikSelect
              field={field}
              meta={getFieldMeta(form, field.name)}
              label={sqlrlField.label}
              options={statusOptions}
            />
          ),
          channel: ({ field, form, sqlrlField }) => (
            <FormikLookup
              schema={ProgramChannelViewSchema}
              fields={["channel"]}
              filter={[]}
              valueField="channel"
              labelField="channel"
              field={field}
              meta={getFieldMeta(form, field.name)}
              label={sqlrlField.label}
            />
          ),
        }}
        initialValues={{
          programId: programId,
        }}
        onSuccess={() => {
          handleSuccess();
          tableRef.current && tableRef.current.refresh();
        }}
        onDelete={handles.close}
        deletable
      />
      <DataTableDialog
        title="Vælg Program"
        open={moveLookupOpen}
        onClose={handleMoveLookupClose}
        onRowSelect={handleMoveProgramSelect}
        schema={ProgramSchema}
        fields={[
          "productionNumber",
          "title",
          "seasonNumber",
          "episodeNumber",
          "productionYear",
          "productionCountry",
          "programId",
        ]}
        widths={{
          title: 280,
        }}
      />
    </React.Fragment>
  );
};
