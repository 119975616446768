import React from "react";
import { RouteComponentProps } from "@reach/router";

// Utilities
import { ISqlrlSchemaPayload } from "@discoverr/ui/utilities/sqlrl";

// Containers
import { DialogContainer } from "@discoverr/ui/containers/DialogContainer";

// Components
import {
  ISqlrlWindowDataTableHandles,
  SqlrlWindowDataTable
} from "@discoverr/ui/components/Table/SqlrlWindowDataTable";
import { TableContainer } from "@discoverr/ui/components/Table/TableContainer";

// Schemas
import { IgnoreWordSchema } from "schema";

//
export interface IIgnoreProps extends RouteComponentProps {}
export const Ignore: React.FunctionComponent<IIgnoreProps> = props => {
  const dialogContainer = DialogContainer.useContainer();

  const tableRef = React.useRef<
    ISqlrlWindowDataTableHandles<typeof IgnoreWordSchema>
  >();

  const handleRowClick = (
    ignoreWord: ISqlrlSchemaPayload<typeof IgnoreWordSchema>
  ) => {
    dialogContainer.confirm.create({
      title: "Slet ord",
      message: "Slet ”" + ignoreWord.word + "” fra listen.",
      status: "warning",
      onConfirm: async () => {
        await IgnoreWordSchema.delete({
          filter: [{ fieldName: "rowId", value: ignoreWord.rowId }]
        });

        if (tableRef.current) {
          tableRef.current.refresh();
        }
      }
    });
  };

  const hancleCreate = () => {
    dialogContainer.textField.create({
      title: "Tilføj ord",
      message: "Tilføj ord til listen.",
      label: "Ord",
      onSubmit: async value => {
        if (value) {
          try {
            await IgnoreWordSchema.insert({ word: value });

            if (tableRef.current) {
              tableRef.current.refresh();
            }
          } catch (error) {}
        } else {
          dialogContainer.alert.create({
            title: "Tilføjelse fejlede",
            message: "Udfyld venligst 'Ord'.",
            status: "error",
            onClose: () => {},
            onExited: () => {}
          });
        }
      }
    });
  };
  return (
    <React.Fragment>
      <TableContainer>
        <SqlrlWindowDataTable
          schema={IgnoreWordSchema}
          innerRef={tableRef}
          title="Ignorereingsliste"
          fields={["word", "created", "rowId"]}
          contextMenuItems={[
            {
              label: "Tilføj ord",
              onClick: hancleCreate
            }
          ]}
          onRowClick={handleRowClick}
        />
      </TableContainer>
    </React.Fragment>
  );
};
