import React from "react";

// Backend
import { backend } from "backend";

// Utilities
import { ISqlrlSchemaPayload } from "@discoverr/ui/utilities/sqlrl";

// Models
import { ProgramSchema } from "schema";
import { VirtualizedImageList } from "@discoverr/ui/components/List";
import Box from "@material-ui/core/Box";
import { CircularProgress } from "@material-ui/core";

export interface IFrame {
  fileName: string;
  path: string;
}

//
export interface IProgramImageTabProps {
  data: Pick<ISqlrlSchemaPayload<typeof ProgramSchema>, "programId">;
}
export const ProgramImageTab: React.FunctionComponent<IProgramImageTabProps> = ({
  data,
}) => {
  const [frames, setFrames] = React.useState<IFrame[] | null>(null);

  const getFrames = async (programId: string) => {
    setFrames(null);
    try {
      const res = await backend.get("broadcast.approval.getFrames", {
        params: {
          programId,
        },
      });
      if (res.data) {
        setFrames(res.data);
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    getFrames(data.programId);
  }, []);

  if (!frames) {
    return (
      <Box
        height={"100%"}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <VirtualizedImageList
      data={frames}
      imageFieldsName="path"
      imageHeight={1080}
      imageWidth={1920}
    />
  );
};
