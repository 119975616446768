import React from "react";
import { RouteComponentProps } from "@reach/router";

// Backend
import { backend, baseMemberAppURL, baseURL } from "backend";

// Schema
import {
  MemberSchema,
  MemberProgramViewSchema,
  MemberContractLinkViewSchema,
  IMemberContractLinkViewSchemaPayload,
  MemberContractLinkSchema,
} from "schema";

// Containers
import { DialogContainer } from "@discoverr/ui/containers/DialogContainer";

// Components
import { TableContainer } from "@discoverr/ui/components/Table/TableContainer";
import { SqlrlDetailView } from "@discoverr/ui/components/Detail/SqlrlDetailView";
import {
  SqlrlDetailsModal,
  useSqlrlDetailsModalLink,
} from "@discoverr/ui/components/Modal/SqlrlDetailsModal";
import {
  SqlrlWindowDataTable,
  ISqlrlWindowDataTableHandles,
} from "@discoverr/ui/components/Table/SqlrlWindowDataTable";
import Box from "@material-ui/core/Box";
import { ISqlrlSchemaPayload } from "@discoverr/ui/utilities/sqlrl";

//
export interface IMemberProps extends RouteComponentProps {}
export const Member: React.FunctionComponent<IMemberProps> = (props) => {
  const dialogContainer = DialogContainer.useContainer();
  const tableRef =
    React.useRef<ISqlrlWindowDataTableHandles<typeof MemberSchema>>();

  const contractTableRef =
    React.useRef<
      ISqlrlWindowDataTableHandles<typeof MemberContractLinkViewSchema>
    >();

  const [link, handles] = useSqlrlDetailsModalLink();

  const handleRowClick = (member: ISqlrlSchemaPayload<typeof MemberSchema>) => {
    handles.view([{ fieldName: "memberId", value: member.memberId }]);
  };

  const handleSyncRetsys = async () => {
    try {
      dialogContainer.loading.start({
        title: "Indlæser...",
        message: "Synkronisere med retsys",
      });
      await backend.get("/sqlrl/custom/sp_retsys_member_sync");
      handles.close();
      if (tableRef.current) {
        tableRef.current.refresh();
      }
    } catch (error) {
      dialogContainer.error.handleError(error);
    } finally {
      dialogContainer.loading.stop();
    }
  };

  const handleLogOn = async (memberId: number) => {
    const res = await backend.get("member.login", {
      params: {
        memberId,
      },
    });
    if (res.data.accessToken) {
      window.open(
        baseMemberAppURL + "?accessToken=" + res.data.accessToken,
        "_blank"
      );
    }
  };

  const handleGenerateApprovalLines = async (memberId: number) => {
    dialogContainer.textField.create({
      title: "Indtast visningsår",
      message: "",
      label: "Visningsår",
      type: "number",
      closeText: "Annuller",
      submitText: "Næste",
      onSubmit: (value) => {
        if (value) {
          setTimeout(() => {
            handleGenerateApprovalLinesKeep(memberId, parseInt(value));
          });
        }
      },
    });
  };

  const handleGenerateApprovalLinesKeep = (
    memberId: number,
    displayYear: number
  ) => {
    dialogContainer.radioGroup.create({
      title: "Genererer linjer",
      options: [
        { label: "Behold eksisternde ændringer", value: "KEEP" },
        { label: "Erstat eksisternde ændringer", value: "REPLACE" },
      ],
      closeText: "Annuller",
      submitText: "Opret",
      onSubmit: (option) => {
        if (option) {
          if (option.value == "REPLACE") {
            backend
              .get("/sqlrl/custom/sp_retsys_member_program_generate", {
                params: {
                  memberId,
                  displayYear,
                },
              })
              .catch(dialogContainer.error.handleError);
          } else if (option.value == "KEEP") {
            backend
              .get("/sqlrl/custom/sp_retsys_member_program_generate_onlynew", {
                params: {
                  memberId,
                  displayYear,
                },
              })
              .catch(dialogContainer.error.handleError);
          }
        }
      },
    });
  };

  const handleContractClick = (
    row: Pick<IMemberContractLinkViewSchemaPayload, "memberContractLinkId">
  ) => {
    dialogContainer.radioGroup.create({
      title: "Vælg handling",
      options: [
        { label: "Åben kontrakt", value: "OPEN" },
        { label: "Slet kontrakt", value: "DELETE" },
      ],
      closeText: "Annuller",
      submitText: "Udfør",
      onSubmit: (option) => {
        if (option) {
          if (option.value == "OPEN") {
            window.open(
              baseURL +
                "member.contract.download?memberContractLinkId=" +
                row.memberContractLinkId,
              "_blank"
            );
          } else if (option.value == "DELETE") {
            setTimeout(() => {
              handleContractDelete(row);
            });
          }
        }
      },
    });
  };

  function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const handleContractDelete = (
    row: Pick<IMemberContractLinkViewSchemaPayload, "memberContractLinkId">
  ) => {
    dialogContainer.textField.create({
      title: `Hvad er adgangskoden?`,
      message: "Indtast koden for at slette.",
      closeText: "Annuller",
      submitText: "Udfør",
      type: "password",
      onSubmit: (value) => {
        if (value === "7913") {
          MemberContractLinkSchema.delete({
            filter: [
              {
                fieldName: "memberContractLinkId",
                value: row.memberContractLinkId,
              },
            ],
          })
            .then(
              () =>
                contractTableRef.current && contractTableRef.current.refresh()
            )
            .catch(dialogContainer.error.handleError);
        } else {
          setTimeout(() => {
            dialogContainer.alert.create({
              title: "Forkert svar",
              message: "Du kan prøve igen for at gennemføre sletning.",
            });
          });
        }
      },
    });
  };

  return (
    <TableContainer>
      <SqlrlWindowDataTable
        innerRef={tableRef}
        schema={MemberSchema}
        title="Medlemmer"
        widths={{ memberId: 60 }}
        fields={[
          "memberId",
          "name",
          "phoneNumber",
          "mobile",
          "email",
          "member",
        ]}
        contextMenuItems={[
          { label: "Synk. Retsys", onClick: handleSyncRetsys },
        ]}
        onRowClick={handleRowClick}
      />
      <SqlrlDetailsModal
        schema={MemberSchema}
        fields={["memberId", "name"]}
        link={link}
        caption="Medlemmer"
        title="name"
        editable={false}
        contextMenuItems={[
          {
            label: "Genererer godkendelseslinjer",
            onClick: () =>
              link.filter &&
              handleGenerateApprovalLines(link.filter[0].value as number),
          },
        ]}
        tabs={[
          {
            label: "General",
            renderer: ({ data }) => (
              <Box p={3}>
                <SqlrlDetailView
                  schema={MemberSchema}
                  fields={[
                    "memberId",
                    "memberGuid",
                    "name",
                    "address",
                    "postCode",
                    "city",
                    "phoneNumber",
                    "mobile",
                    "email",
                    "cpr",
                    "member",
                    "alias1",
                    "alias2",
                    "alias3",
                    "alias4",
                    "alias5",
                    "alias6",
                    "alias7",
                    "alias8",
                    "alias9",
                  ]}
                  filter={[
                    {
                      fieldName: "memberId",
                      value: data.memberId,
                    },
                  ]}
                  menuBarItems={[
                    {
                      label: "Log på",
                      iconName: "open_in_new",
                      onClick: () => handleLogOn(data.memberId),
                    },
                  ]}
                  title="Person"
                />
              </Box>
            ),
          },
          {
            label: "Godkendelse",
            renderer: ({ data }) => (
              <SqlrlWindowDataTable
                schema={MemberProgramViewSchema}
                title="Medlem/Program - Godkendelse"
                fields={[
                  "displayYear",
                  "status",
                  "programChannel",
                  "programTitle",
                  "programSeasonNumber",
                  "programEpisodeNumber",
                ]}
                widths={{
                  programTitle: 320,
                }}
                filter={[
                  {
                    fieldName: "memberId",
                    value: data.memberId,
                  },
                ]}
              />
            ),
          },
          {
            label: "Kontrakter",
            renderer: ({ data }) => (
              <SqlrlWindowDataTable
                innerRef={contractTableRef}
                schema={MemberContractLinkViewSchema}
                fields={[
                  "memberContractTitle",
                  "filename",
                  "comment",
                  "memberContractLinkId",
                ]}
                filter={[
                  {
                    fieldName: "memberId",
                    value:
                      data.memberId || "{00000000-0000-0000-0000-000000000000}",
                  },
                ]}
                title="Kontrakter"
                onRowClick={handleContractClick}
              />
            ),
          },
        ]}
      />
    </TableContainer>
  );
};
