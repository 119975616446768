import axios from "axios";
import LRU from "lru-cache";
const { configure } = require("axios-hooks");

// Constants
const getBaseUrl = () => {
  return process.env.REACT_APP_API;
};

const getBaseMemberAppUrl = () => {
  if (process.env.REACT_APP_API === "https://api.eagleeyes.cloud/") {
    return "https://medlem.eagleeyes.cloud/";
  }
  return "https://medlemdev.eagleeyes.cloud/";
};

export const baseURL = getBaseUrl();
export const baseMemberAppURL = getBaseMemberAppUrl();

//
export const backend = axios.create({
  baseURL,
  timeout: 20000,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

const cache = new LRU({ max: 10, maxAge: 1000 * 5 });
configure({ axios: backend, cache });
