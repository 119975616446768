import React from "react";
import { RouteComponentProps } from "@reach/router";

// Utilities
import { ISqlrlSchemaPayload } from "@discoverr/ui/utilities/sqlrl";

// Containers
import { DialogContainer } from "@discoverr/ui/containers/DialogContainer";

// Components
import Box from "@material-ui/core/Box";
import {
  ISqlrlWindowDataTableHandles,
  SqlrlWindowDataTable
} from "@discoverr/ui/components/Table/SqlrlWindowDataTable";
import {
  SqlrlDetailsModal,
  useSqlrlDetailsModalLink
} from "@discoverr/ui/components/Modal/SqlrlDetailsModal";
import { SqlrlDetailView } from "@discoverr/ui/components/Detail/SqlrlDetailView";
import { SqlrlAutoFormik } from "@discoverr/ui/components/Formik/SqlrlAutoFormik";
import { TableContainer } from "@discoverr/ui/components/Table/TableContainer";

// Schemas
import { MemberProgramYearSchema } from "schema";

//
export interface IMemberProgramYearProps extends RouteComponentProps {}
export const MemberProgramYear: React.FunctionComponent<IMemberProgramYearProps> = props => {
  const dialogContainer = DialogContainer.useContainer();

  const tableRef = React.useRef<
    ISqlrlWindowDataTableHandles<typeof MemberProgramYearSchema>
  >();

  const [link, handles] = useSqlrlDetailsModalLink<
    typeof MemberProgramYearSchema
  >();

  const handleRowClick = (
    row: ISqlrlSchemaPayload<typeof MemberProgramYearSchema>
  ) => {
    handles.view([{ fieldName: "year", value: row.year }]);
  };

  const hancleCreate = () => {
    handles.create();
  };

  return (
    <React.Fragment>
      <TableContainer>
        <SqlrlWindowDataTable
          schema={MemberProgramYearSchema}
          innerRef={tableRef}
          title="Member/Program - Year"
          fields={["year", "closed"]}
          contextMenuItems={[
            {
              label: "Opret ny",
              onClick: hancleCreate
            }
          ]}
          onRowClick={handleRowClick}
        />
      </TableContainer>
      <SqlrlDetailsModal
        schema={MemberProgramYearSchema}
        fields={["year", "closed"]}
        link={link}
        caption="År"
        title={"year"}
        editable={true}
        editRenderer={({ data, close, refresh }) => (
          <SqlrlAutoFormik
            initialValues={{}}
            schema={MemberProgramYearSchema}
            fields={["year", "closed"]}
            returnFields={[]}
            filter={data ? [{ fieldName: "year", value: data.year }] : []}
            onEdit={close}
            onCreate={close}
          />
        )}
        tabs={[
          {
            label: "General",
            renderer: ({ data }) => (
              <Box p={3}>
                <SqlrlDetailView
                  schema={MemberProgramYearSchema}
                  fields={["year", "closed"]}
                  filter={[
                    {
                      fieldName: "year",
                      value: data.year
                    }
                  ]}
                  title="Bruger"
                />
              </Box>
            )
          }
        ]}
      />
    </React.Fragment>
  );
};
