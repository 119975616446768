import React from "react";
import { RouteComponentProps } from "@reach/router";

// Components
import Box from "@material-ui/core/Box";

import { TableContainer } from "@discoverr/ui/components/Table/TableContainer";
import {
  SqlrlWindowDataTable,
  ISqlrlWindowDataTableHandles,
} from "@discoverr/ui/components/Table/SqlrlWindowDataTable";
import {
  SqlrlDetailsModal,
  useSqlrlDetailsModalLink,
} from "@discoverr/ui/components/Modal/SqlrlDetailsModal";
import { SqlrlDetailView } from "@discoverr/ui/components/Detail/SqlrlDetailView";
import { getFieldMeta } from "@discoverr/ui/components/Formik/MagicFormik";
import { SqlrlFormik } from "@discoverr/ui/components/Formik/SqlrlFormik";
import { Form } from "@discoverr/ui/components/Form";
import { FormikSelect, FormikTextField } from "@discoverr/ui/components/Formik";

// Schema
import { UserSchema } from "schema";
import { ISqlrlSchemaPayload } from "@discoverr/ui/utilities/sqlrl";

// Constants
const roleOptions = [
  { label: "Admin", value: "ADMIN" },
  { label: "User", value: "USER" },
  { label: "Super User", value: "SUPERUSER" },
  { label: "Guest", value: "GUEST" },
];

//
export interface IUsersProps extends RouteComponentProps {}
export const Users: React.FunctionComponent<IUsersProps> = (props) => {
  const tableRef = React.useRef<
    ISqlrlWindowDataTableHandles<typeof UserSchema>
  >();

  const [link, handles] = useSqlrlDetailsModalLink<typeof UserSchema>();

  const handleRowClick = (
    broadcast: ISqlrlSchemaPayload<typeof UserSchema>
  ) => {
    handles.view([{ fieldName: "userId", value: broadcast.userId }]);
  };

  const handleCreate = () => {
    handles.create();
  };
  const handleSuccess = () => {
    handles.close();
    if (tableRef.current) {
      tableRef.current.refresh();
    }
  };
  return (
    <React.Fragment>
      <TableContainer>
        <SqlrlWindowDataTable
          innerRef={tableRef}
          schema={UserSchema}
          title="Brugere"
          fields={["userId", "firstName", "lastName", "email", "role"]}
          onRowClick={handleRowClick}
          contextMenuItems={[
            {
              label: "Opret Bruger",
              onClick: handleCreate,
            },
          ]}
        />
      </TableContainer>
      <SqlrlDetailsModal
        schema={UserSchema}
        fields={["userId", "firstName", "lastName"]}
        link={link}
        caption="Bruger"
        title={(item) => `${item.firstName} ${item.lastName}`}
        editable={true}
        editRenderer={({ data, close, refresh }) => (
          <SqlrlFormik
            schema={UserSchema}
            fields={["firstName", "lastName", "email", "password", "role"]}
            returnFields={[]}
            filter={data ? [{ fieldName: "userId", value: data.userId }] : []}
            initialValues={{}}
            onSuccess={() => {
              handleSuccess();
              refresh && refresh(true);
            }}
            onDelete={close}
          >
            {({ SqlrlField, handleSubmit, deleteItem }) => (
              <Form onSubmit={handleSubmit} onDelete={deleteItem}>
                <SqlrlField name="firstName">
                  {({ field, form }, { label }) => (
                    <FormikTextField
                      field={field}
                      meta={getFieldMeta(form, field.name)}
                      label={label}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  )}
                </SqlrlField>
                <SqlrlField name="lastName">
                  {({ field, form }, { label }) => (
                    <FormikTextField
                      field={field}
                      meta={getFieldMeta(form, field.name)}
                      label={label}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  )}
                </SqlrlField>
                <SqlrlField name="email">
                  {({ field, form }, { label }) => (
                    <FormikTextField
                      field={field}
                      meta={getFieldMeta(form, field.name)}
                      label={label}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  )}
                </SqlrlField>
                <SqlrlField name="password">
                  {({ field, form }, { label }) => (
                    <FormikTextField
                      field={field}
                      meta={getFieldMeta(form, field.name)}
                      label={label}
                      type="password"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  )}
                </SqlrlField>
                <SqlrlField name="role">
                  {({ field, form }, { label }) => (
                    <FormikSelect
                      field={field}
                      meta={getFieldMeta(form, field.name)}
                      label={label}
                      options={roleOptions}
                    />
                  )}
                </SqlrlField>
              </Form>
            )}
          </SqlrlFormik>
        )}
        tabs={[
          {
            label: "General",
            renderer: ({ data }) => (
              <Box p={3}>
                <SqlrlDetailView
                  schema={UserSchema}
                  fields={["firstName", "lastName", "email", "role"]}
                  filter={[
                    {
                      fieldName: "userId",
                      value: data.userId,
                    },
                  ]}
                  title="Bruger"
                />
              </Box>
            ),
          },
        ]}
      />
    </React.Fragment>
  );
};
