import React from "react";

// Containers
import { AuthContainer } from "containers/AuthContainer";

// Utilities
import { ISqlrlSchemaPayload } from "@discoverr/ui/utilities/sqlrl";

// Components
import {
  SqlrlWindowDataTable,
  ISqlrlWindowDataTableHandles,
} from "@discoverr/ui/components/Table/SqlrlWindowDataTable";
import { DataTableDialog } from "@discoverr/ui/components/Dialog";

import {
  SqlrlAutoFormikModal,
  useSqlrlAutoFormikModalLink,
} from "@discoverr/ui/components/Modal/SqlrlAutoFormikModal";
import {
  FormikLookup,
  getFieldMeta,
  FormikTextField,
  FormikDatePicker,
  FormikTimePicker,
  FormikSelect,
  IFormikSelectOption,
} from "@discoverr/ui/components/Formik";
import { FormikDateTimePicker } from "@discoverr/ui/components/Formik/FormikDateTimePicker";

// Models
import {
  MemberProgramSchema,
  IBroadcastSchemaPayload,
  MemberProgramViewSchema,
  ProgramSchema,
  IProgramSchemaPayload,
  ProgramChannelViewSchema,
  PositionSchema,
} from "schema";
import { DialogContainer } from "@discoverr/ui/containers/DialogContainer";

// Constants
const statusOptions: IFormikSelectOption[] = [
  { value: "AUTO", label: "AUTO" },
  { value: "GODKENDT", label: "GODKENDT" },
  { value: "KLAR", label: "KLAR" },
  { value: "OCR", label: "OCR" },
  { value: "UDSKUDT", label: "UDSKUDT" },
];

//
export interface IMemberTabProps {
  data: Pick<ISqlrlSchemaPayload<typeof ProgramSchema>, "programId">;
}
export const MemberTab: React.FunctionComponent<IMemberTabProps> = ({
  data,
}) => {
  const dialogContainer = DialogContainer.useContainer();
  const tableRef = React.useRef<
    ISqlrlWindowDataTableHandles<typeof MemberProgramViewSchema>
  >();

  const [link, handles] = useSqlrlAutoFormikModalLink<
    typeof MemberProgramSchema
  >();

  const handleSuccess = () => {
    if (tableRef.current) {
      tableRef.current.refresh();
    }
  };
  const [moveLookupOpen, setMoveLookup] = React.useState(false);

  const handleMoveLookupClose = () => {
    setMoveLookup(false);
  };

  const handleMovePeopleOpen = () => {
    setMoveLookup(true);
  };
  const handleMoveMemberSelect = (
    row: Pick<IProgramSchemaPayload, "programId">
  ) => {
    dialogContainer.confirm.create({
      title: "Bekræft flytning",
      message: "Medlemmer vil blive flyttet til det valgte program.",
      confirmText: "Bekræft",
      closeText: "Annuller",
      onConfirm: () => {
        MemberProgramSchema.update(
          { programId: row.programId },
          { filter: [{ fieldName: "programId", value: data.programId }] }
        )
          .then(() => {
            handleMoveLookupClose();
            tableRef.current && tableRef.current.refresh();
          })
          .catch(dialogContainer.error.handleError);
      },
    });
  };

  return (
    <React.Fragment>
      <SqlrlWindowDataTable
        innerRef={tableRef}
        schema={MemberProgramViewSchema}
        fields={[
          "displayYear",
          "memberId",
          "memberName",
          "jobFunction",
          "recruitmentForm",
          "rowId",
        ]}
        filter={[
          {
            fieldName: "programId",
            value: data.programId,
          },
        ]}
        widths={{
          memberId: 200,
          memberName: 250,
          recruitmentForm: 250,
          jobFunction: 220,
        }}
        title="Medlemmer"
        menuBarItems={[]}
        onRowClick={(row) => {
          handles.update([{ fieldName: "rowId", value: row.rowId }]);
        }}
        contextMenuItems={[
          {
            label: "Flyt alle Medlemmer",
            onClick: handleMovePeopleOpen,
          },
        ]}
      />
      <SqlrlAutoFormikModal
        title="Message In"
        link={link}
        schema={MemberProgramSchema}
        fields={["jobFunction"]}
        returnFields={[]}
        fieldRendererMap={{
          jobFunction: ({ field, form, sqlrlField }) => (
            <FormikLookup
              schema={PositionSchema}
              fields={["name"]}
              filter={[]}
              valueField="name"
              labelField="name"
              field={field}
              meta={getFieldMeta(form, field.name)}
              label={sqlrlField.label}
            />
          ),
        }}
        initialValues={{}}
        onSuccess={() => {
          handleSuccess();
        }}
        onTransformValues={(values) => ({
          ...values,
          programId: data.programId,
        })}
        onDelete={handles.close}
        deletable
      />
      <DataTableDialog
        title="Vælg Program"
        open={moveLookupOpen}
        onClose={handleMoveLookupClose}
        onRowSelect={handleMoveMemberSelect}
        schema={ProgramSchema}
        fields={[
          "productionNumber",
          "title",
          "seasonNumber",
          "episodeNumber",
          "productionYear",
          "productionCountry",
          "programId",
        ]}
        widths={{
          title: 280,
        }}
      />
    </React.Fragment>
  );
};
