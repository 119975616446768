import React from "react";

// Utilities
import { ISqlrlSchemaPayload } from "@discoverr/ui/utilities/sqlrl";

// Components
import { SqlrlWindowDataTable } from "@discoverr/ui/components/Table/SqlrlWindowDataTable";

// Models
import { ProgramSchema, ProgramBonanzaViewSchema } from "schema";

//
export interface IBroadcastBonanzaListTabProps {
  programId: ISqlrlSchemaPayload<typeof ProgramSchema>["programId"];
}
export const BroadcastBonanzaListTab: React.FunctionComponent<IBroadcastBonanzaListTabProps> = ({
  programId,
}) => {
  return (
    <React.Fragment>
      <SqlrlWindowDataTable
        schema={ProgramBonanzaViewSchema}
        fields={[
          "status",
          "paymentYear",
          "productionNumber",
          "title",
          "click",
          "role",
          "person",
        ]}
        widths={{ title: 300 }}
        filter={[
          {
            fieldName: "programId",
            value: programId,
          },
        ]}
        title="Bonanza"
      />
    </React.Fragment>
  );
};
