import React from "react";
import { RouteComponentProps } from "@reach/router";

// Utilities
import { ISqlrlSchemaPayload } from "@discoverr/ui/utilities/sqlrl";

// Containers
import { DialogContainer } from "@discoverr/ui/containers/DialogContainer";

// Components
import Box from "@material-ui/core/Box";
import {
  ISqlrlWindowDataTableHandles,
  SqlrlWindowDataTable,
} from "@discoverr/ui/components/Table/SqlrlWindowDataTable";
import {
  SqlrlDetailsModal,
  useSqlrlDetailsModalLink,
} from "@discoverr/ui/components/Modal/SqlrlDetailsModal";
import { SqlrlDetailView } from "@discoverr/ui/components/Detail/SqlrlDetailView";
import { SqlrlAutoFormik } from "@discoverr/ui/components/Formik/SqlrlAutoFormik";
import { TableContainer } from "@discoverr/ui/components/Table/TableContainer";

// Schemas
import {
  MemberProgramCommentViewSchema,
  MemberProgramCommentSchema,
} from "schema";

//
export interface IMemberProgramCommentsProps extends RouteComponentProps {}
export const MemberProgramComments: React.FunctionComponent<IMemberProgramCommentsProps> = (
  props
) => {
  const dialogContainer = DialogContainer.useContainer();

  const tableRef = React.useRef<
    ISqlrlWindowDataTableHandles<typeof MemberProgramCommentViewSchema>
  >();

  const [link, handles] = useSqlrlDetailsModalLink<
    typeof MemberProgramCommentViewSchema
  >();

  const handleRowClick = (
    row: ISqlrlSchemaPayload<typeof MemberProgramCommentViewSchema>
  ) => {
    handles.view([
      { fieldName: "memberCommentId", value: row.memberCommentId },
    ]);
  };

  const hancleCreate = () => {
    handles.create();
  };

  return (
    <React.Fragment>
      <TableContainer>
        <SqlrlWindowDataTable
          schema={MemberProgramCommentViewSchema}
          innerRef={tableRef}
          title="Member/Program - Kommentar"
          fields={[
            "memberCommentId",
            "programMemberId",
            "comment",
            "programId",
            "programTitle",
            "programSubtitle",
            "programSeasonNumber",
            "programEpisodeNumber",
            "programProductionNumber",
            "programProductionYear",
          ]}
          onRowClick={handleRowClick}
        />
      </TableContainer>
      <SqlrlDetailsModal
        schema={MemberProgramCommentViewSchema}
        fields={["memberCommentId"]}
        link={link}
        caption="Bruger"
        title={"memberCommentId"}
        editable={false}
        tabs={[
          {
            label: "General",
            renderer: ({ data }) => (
              <Box p={3}>
                <SqlrlDetailView
                  schema={MemberProgramCommentViewSchema}
                  fields={[
                    "memberCommentId",
                    "programMemberId",
                    "comment",
                    "programId",
                    "programTitle",
                    "programSubtitle",
                    "programSeasonNumber",
                    "programEpisodeNumber",
                    "programProductionNumber",
                    "programProductionYear",
                  ]}
                  filter={[
                    {
                      fieldName: "memberCommentId",
                      value: data.memberCommentId,
                    },
                  ]}
                  title="Bruger"
                />
              </Box>
            ),
          },
        ]}
      />
    </React.Fragment>
  );
};
