import React from "react";
import { Router, Redirect, RouteComponentProps } from "@reach/router";

// Components
import { RouterFlexLayout } from "components/Layout";

// Views
import { Frames } from "./views/Frames";
import { Ocr } from "./views/Ocr";
import { Review } from "./views/Review";

//
export interface IToolProps extends RouteComponentProps {
  broadcastId?: string;
}
export const Tool: React.FunctionComponent<IToolProps> = props => {
  return (
    <Router component={RouterFlexLayout}>
      <Frames path="frames" broadcastId={props.broadcastId}></Frames>
      <Ocr path="ocr" broadcastId={props.broadcastId}></Ocr>
      <Redirect from="./" to="frames" />
    </Router>
  );
};
